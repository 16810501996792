/*!
 * Farm Machinery v1
 */
@font-face {
  font-family: CSicons;
  font-style: normal;
  font-weight: normal;
  src: url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.eot?4ujry3");
  src: url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.eot?4ujry3#iefix") format("embedded-opentype"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.ttf?4ujry3") format("truetype"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.woff?4ujry3") format("woff"), url("https://resource.csnstatic.com/retail/vendors/csn-icons/4.0/font/csn-icons.svg?4ujry3#icomoon") format("svg");
}

.csn-theme {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.csn-theme :root {
  --blue: #007CC2;
  --red: #ED1C24;
  --orange: #FF7B00;
  --green: #41DBA0;
  --white: #fff;
  --gray: #9b9b9b;
  --gray-dark: #4a4a4a;
  --primary: #4da32f;
  --primary-dark: #266e44;
  --secondary: #FF7B00;
  --success: #41DBA0;
  --info: #007CC2;
  --warning: #ED1C24;
  --danger: #ED1C24;
  --light: #f6f6f6;
  --dark: #4a4a4a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.csn-theme *,
.csn-theme *::before,
.csn-theme *::after {
  box-sizing: border-box;
}

.csn-theme html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@-ms-viewport {
  width: device-width;
}

.csn-theme article, .csn-theme aside, .csn-theme dialog, .csn-theme figcaption, .csn-theme figure, .csn-theme footer, .csn-theme header, .csn-theme hgroup, .csn-theme main, .csn-theme nav, .csn-theme section {
  display: block;
}

.csn-theme body {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.csn-theme [tabindex="-1"]:focus {
  outline: 0 !important;
}

.csn-theme hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.csn-theme h1, .csn-theme h2, .csn-theme h3, .csn-theme h4, .csn-theme h5, .csn-theme h6 {
  margin-top: 0;
  margin-bottom: 5px;
}

.csn-theme p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.csn-theme abbr[title],
.csn-theme abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

.csn-theme address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

.csn-theme ol,
.csn-theme ul,
.csn-theme dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.csn-theme ol ol,
.csn-theme ul ul,
.csn-theme ol ul,
.csn-theme ul ol {
  margin-bottom: 0;
}

.csn-theme dt {
  font-weight: 600;
}

.csn-theme dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

.csn-theme blockquote {
  margin: 0 0 1rem;
}

.csn-theme dfn {
  font-style: italic;
}

.csn-theme b,
.csn-theme strong {
  font-weight: bolder;
}

.csn-theme small {
  font-size: 80%;
}

.csn-theme sub,
.csn-theme sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.csn-theme sub {
  bottom: -.25em;
}

.csn-theme sup {
  top: -.5em;
}

.csn-theme a {
  color: #266e44;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.csn-theme a:hover {
  color: #266e44;
  text-decoration: underline;
}

.csn-theme a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

.csn-theme a:not([href]):not([tabindex]):hover, .csn-theme a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

.csn-theme a:not([href]):not([tabindex]):focus {
  outline: 0;
}

.csn-theme pre,
.csn-theme code,
.csn-theme kbd,
.csn-theme samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

.csn-theme pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

.csn-theme figure {
  margin: 0 0 1rem;
}

.csn-theme img {
  vertical-align: middle;
  border-style: none;
}

.csn-theme svg:not(:root) {
  overflow: hidden;
}

.csn-theme table {
  border-collapse: collapse;
}

.csn-theme caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9b9b9b;
  text-align: left;
  caption-side: bottom;
}

.csn-theme th {
  text-align: inherit;
}

.csn-theme label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.csn-theme button {
  border-radius: 0;
}

.csn-theme button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.csn-theme input,
.csn-theme button,
.csn-theme select,
.csn-theme optgroup,
.csn-theme textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.csn-theme button,
.csn-theme input {
  overflow: visible;
}

.csn-theme button,
.csn-theme select {
  text-transform: none;
}

.csn-theme button,
.csn-theme html [type="button"],
.csn-theme [type="reset"],
.csn-theme [type="submit"] {
  -webkit-appearance: button;
}

.csn-theme button::-moz-focus-inner,
.csn-theme [type="button"]::-moz-focus-inner,
.csn-theme [type="reset"]::-moz-focus-inner,
.csn-theme [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

.csn-theme input[type="radio"],
.csn-theme input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.csn-theme input[type="date"],
.csn-theme input[type="time"],
.csn-theme input[type="datetime-local"],
.csn-theme input[type="month"] {
  -webkit-appearance: listbox;
}

.csn-theme textarea {
  overflow: auto;
  resize: vertical;
}

.csn-theme fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.csn-theme legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.csn-theme progress {
  vertical-align: baseline;
}

.csn-theme [type="number"]::-webkit-inner-spin-button,
.csn-theme [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.csn-theme [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.csn-theme [type="search"]::-webkit-search-cancel-button,
.csn-theme [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.csn-theme ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.csn-theme output {
  display: inline-block;
}

.csn-theme summary {
  display: list-item;
  cursor: pointer;
}

.csn-theme template {
  display: none;
}

.csn-theme [hidden] {
  display: none !important;
}

.csn-theme h1, .csn-theme h2, .csn-theme h3, .csn-theme h4, .csn-theme h5, .csn-theme h6,
.csn-theme .h1, .csn-theme .h2, .csn-theme .h3, .csn-theme .h4, .csn-theme .h5, .csn-theme .h6 {
  margin-bottom: 5px;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

.csn-theme h1, .csn-theme .h1 {
  font-size: 1.75rem;
}

.csn-theme h2, .csn-theme .h2 {
  font-size: 1.575rem;
}

.csn-theme h3, .csn-theme .h3 {
  font-size: 1.4rem;
}

.csn-theme h4, .csn-theme .h4 {
  font-size: 1.225rem;
}

.csn-theme h5, .csn-theme .h5 {
  font-size: 1.05rem;
}

.csn-theme h6, .csn-theme .h6 {
  font-size: 0.875rem;
}

.csn-theme .lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.csn-theme .display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.2;
}

.csn-theme .display-2 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.2;
}

.csn-theme .display-3 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.csn-theme .display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.csn-theme hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.csn-theme small,
.csn-theme .small {
  font-size: 80%;
  font-weight: 400;
}

.csn-theme mark,
.csn-theme .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.csn-theme .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.csn-theme .list-inline {
  padding-left: 0;
  list-style: none;
}

.csn-theme .list-inline-item {
  display: inline-block;
}

.csn-theme .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.csn-theme .initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.csn-theme .blockquote {
  margin-bottom: 10px;
  font-size: 1.09375rem;
}

.csn-theme .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #9b9b9b;
}

.csn-theme .blockquote-footer::before {
  content: "\2014 \00A0";
}

.csn-theme [class^="icon-"],
.csn-theme [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: CSicons, serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.csn-theme .icon-account::before {
  content: "\e900";
}

.csn-theme .icon-add-circle::before {
  content: "\e901";
}

.csn-theme .icon-add::before {
  content: "\e902";
}

.csn-theme .icon-arrow-down::before {
  content: "\e903";
}

.csn-theme .icon-arrow-left::before {
  content: "\e904";
}

.csn-theme .icon-arrow-right::before {
  content: "\e905";
}

.csn-theme .icon-arrow-up::before {
  content: "\e906";
}

.csn-theme .icon-body-type::before {
  content: "\e907";
}

.csn-theme .icon-calendar::before {
  content: "\e908";
}

.csn-theme .icon-call::before {
  content: "\e909";
}

.csn-theme .icon-camera::before {
  content: "\e90a";
}

.csn-theme .icon-circle-close::before {
  content: "\e90b";
}

.csn-theme .icon-circle-sell::before {
  content: "\e90c";
}

.csn-theme .icon-circle-tick::before {
  content: "\e90d";
}

.csn-theme .icon-close::before {
  content: "\e90e";
}

.csn-theme .icon-cog::before {
  content: "\e90f";
}

.csn-theme .icon-creditcard::before {
  content: "\e910";
}

.csn-theme .icon-detail-list::before {
  content: "\e911";
}

.csn-theme .icon-document::before {
  content: "\e912";
}

.csn-theme .icon-drive-type::before {
  content: "\e913";
}

.csn-theme .icon-edit::before {
  content: "\e914";
}

.csn-theme .icon-email::before {
  content: "\e915";
}

.csn-theme .icon-engine::before {
  content: "\e916";
}

.csn-theme .icon-external-link::before {
  content: "\e917";
}

.csn-theme .icon-fav-filled::before {
  content: "\e918";
}

.csn-theme .icon-favourite::before {
  content: "\e919";
}

.csn-theme .icon-filter::before {
  content: "\e91a";
}

.csn-theme .icon-finance::before {
  content: "\e91b";
}

.csn-theme .icon-home::before {
  content: "\e91c";
}

.csn-theme .icon-hrz-dots::before {
  content: "\e91d";
}

.csn-theme .icon-idea-bulb::before {
  content: "\e91e";
}

.csn-theme .icon-info::before {
  content: "\e91f";
}

.csn-theme .icon-location::before {
  content: "\e920";
}

.csn-theme .icon-lock::before {
  content: "\e921";
}

.csn-theme .icon-membenefits::before {
  content: "\e922";
}

.csn-theme .icon-mobile::before {
  content: "\e923";
}

.csn-theme .icon-notifications::before {
  content: "\e924";
}

.csn-theme .icon-odometer::before {
  content: "\e925";
}

.csn-theme .icon-play::before {
  content: "\e926";
}

.csn-theme .icon-price-drop::before {
  content: "\e927";
}

.csn-theme .icon-question::before {
  content: "\e928";
}

.csn-theme .icon-refresh::before {
  content: "\e929";
}

.csn-theme .icon-saved-favourites::before {
  content: "\e92a";
}

.csn-theme .icon-saved-searches::before {
  content: "\e92b";
}

.csn-theme .icon-search::before {
  content: "\e92c";
}

.csn-theme .icon-send::before {
  content: "\e930";
}

.csn-theme .icon-share::before {
  content: "\e931";
}

.csn-theme .icon-sms-message::before {
  content: "\e932";
}

.csn-theme .icon-star-filled::before {
  content: "\e933";
}

.csn-theme .icon-star-half-filled::before {
  content: "\e934";
}

.csn-theme .icon-star::before {
  content: "\e935";
}

.csn-theme .icon-tick::before {
  content: "\e936";
}

.csn-theme .icon-transmission::before {
  content: "\e937";
}

.csn-theme .icon-trash::before {
  content: "\e938";
}

.csn-theme .icon-video::before {
  content: "\e939";
}

.csn-theme .icon-vrt-dots::before {
  content: "\e93a";
}

.csn-theme .icon-warning::before {
  content: "\e93b";
}

.csn-theme .icon-bookmark::before {
  content: "\e93c";
}

.csn-theme .img-fluid {
  max-width: 100%;
  height: auto;
}

.csn-theme .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.csn-theme .figure {
  display: inline-block;
}

.csn-theme .figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.csn-theme .figure-caption {
  font-size: 90%;
  color: #9b9b9b;
}

.csn-theme code,
.csn-theme kbd,
.csn-theme pre,
.csn-theme samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.csn-theme code {
  font-size: 87.5%;
  color: #FF7B00;
  word-break: break-word;
}

a > .csn-theme code {
  color: inherit;
}

.csn-theme kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

.csn-theme kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

.csn-theme pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

.csn-theme pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.csn-theme .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.csn-theme .container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .csn-theme .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .csn-theme .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .csn-theme .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .csn-theme .container {
    max-width: 1140px;
  }
}

.csn-theme .container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.csn-theme .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.csn-theme .no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.csn-theme .no-gutters > .col,
.csn-theme .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.csn-theme .col-1, .csn-theme .col-2, .csn-theme .col-3, .csn-theme .col-4, .csn-theme .col-5, .csn-theme .col-6, .csn-theme .col-7, .csn-theme .col-8, .csn-theme .col-9, .csn-theme .col-10, .csn-theme .col-11, .csn-theme .col-12, .csn-theme .col,
.csn-theme .col-auto, .csn-theme .col-sm-1, .csn-theme .col-sm-2, .csn-theme .col-sm-3, .csn-theme .col-sm-4, .csn-theme .col-sm-5, .csn-theme .col-sm-6, .csn-theme .col-sm-7, .csn-theme .col-sm-8, .csn-theme .col-sm-9, .csn-theme .col-sm-10, .csn-theme .col-sm-11, .csn-theme .col-sm-12, .csn-theme .col-sm,
.csn-theme .col-sm-auto, .csn-theme .col-md-1, .csn-theme .col-md-2, .csn-theme .col-md-3, .csn-theme .col-md-4, .csn-theme .col-md-5, .csn-theme .col-md-6, .csn-theme .col-md-7, .csn-theme .col-md-8, .csn-theme .col-md-9, .csn-theme .col-md-10, .csn-theme .col-md-11, .csn-theme .col-md-12, .csn-theme .col-md,
.csn-theme .col-md-auto, .csn-theme .col-lg-1, .csn-theme .col-lg-2, .csn-theme .col-lg-3, .csn-theme .col-lg-4, .csn-theme .col-lg-5, .csn-theme .col-lg-6, .csn-theme .col-lg-7, .csn-theme .col-lg-8, .csn-theme .col-lg-9, .csn-theme .col-lg-10, .csn-theme .col-lg-11, .csn-theme .col-lg-12, .csn-theme .col-lg,
.csn-theme .col-lg-auto, .csn-theme .col-xl-1, .csn-theme .col-xl-2, .csn-theme .col-xl-3, .csn-theme .col-xl-4, .csn-theme .col-xl-5, .csn-theme .col-xl-6, .csn-theme .col-xl-7, .csn-theme .col-xl-8, .csn-theme .col-xl-9, .csn-theme .col-xl-10, .csn-theme .col-xl-11, .csn-theme .col-xl-12, .csn-theme .col-xl,
.csn-theme .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.csn-theme .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.csn-theme .col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.csn-theme .col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.csn-theme .col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.csn-theme .col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.csn-theme .col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.csn-theme .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.csn-theme .col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.csn-theme .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.csn-theme .col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.csn-theme .col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.csn-theme .col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.csn-theme .col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.csn-theme .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.csn-theme .order-first {
  -ms-flex-order: -1;
  order: -1;
}

.csn-theme .order-last {
  -ms-flex-order: 13;
  order: 13;
}

.csn-theme .order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.csn-theme .order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.csn-theme .order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.csn-theme .order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.csn-theme .order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.csn-theme .order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.csn-theme .order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.csn-theme .order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.csn-theme .order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.csn-theme .order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.csn-theme .order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.csn-theme .order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.csn-theme .order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.csn-theme .offset-1 {
  margin-left: 8.333333%;
}

.csn-theme .offset-2 {
  margin-left: 16.666667%;
}

.csn-theme .offset-3 {
  margin-left: 25%;
}

.csn-theme .offset-4 {
  margin-left: 33.333333%;
}

.csn-theme .offset-5 {
  margin-left: 41.666667%;
}

.csn-theme .offset-6 {
  margin-left: 50%;
}

.csn-theme .offset-7 {
  margin-left: 58.333333%;
}

.csn-theme .offset-8 {
  margin-left: 66.666667%;
}

.csn-theme .offset-9 {
  margin-left: 75%;
}

.csn-theme .offset-10 {
  margin-left: 83.333333%;
}

.csn-theme .offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .csn-theme .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .csn-theme .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .csn-theme .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .csn-theme .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .csn-theme .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .csn-theme .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .csn-theme .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .csn-theme .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .csn-theme .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .csn-theme .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .csn-theme .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .csn-theme .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .csn-theme .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .csn-theme .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .csn-theme .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .csn-theme .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .csn-theme .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .csn-theme .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .csn-theme .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .csn-theme .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .csn-theme .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .csn-theme .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .csn-theme .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .csn-theme .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .csn-theme .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .csn-theme .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .csn-theme .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .csn-theme .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .csn-theme .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .csn-theme .offset-sm-0 {
    margin-left: 0;
  }
  .csn-theme .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .csn-theme .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .csn-theme .offset-sm-3 {
    margin-left: 25%;
  }
  .csn-theme .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .csn-theme .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .csn-theme .offset-sm-6 {
    margin-left: 50%;
  }
  .csn-theme .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .csn-theme .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .csn-theme .offset-sm-9 {
    margin-left: 75%;
  }
  .csn-theme .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .csn-theme .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .csn-theme .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .csn-theme .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .csn-theme .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .csn-theme .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .csn-theme .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .csn-theme .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .csn-theme .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .csn-theme .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .csn-theme .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .csn-theme .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .csn-theme .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .csn-theme .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .csn-theme .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .csn-theme .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .csn-theme .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .csn-theme .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .csn-theme .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .csn-theme .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .csn-theme .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .csn-theme .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .csn-theme .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .csn-theme .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .csn-theme .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .csn-theme .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .csn-theme .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .csn-theme .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .csn-theme .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .csn-theme .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .csn-theme .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .csn-theme .offset-md-0 {
    margin-left: 0;
  }
  .csn-theme .offset-md-1 {
    margin-left: 8.333333%;
  }
  .csn-theme .offset-md-2 {
    margin-left: 16.666667%;
  }
  .csn-theme .offset-md-3 {
    margin-left: 25%;
  }
  .csn-theme .offset-md-4 {
    margin-left: 33.333333%;
  }
  .csn-theme .offset-md-5 {
    margin-left: 41.666667%;
  }
  .csn-theme .offset-md-6 {
    margin-left: 50%;
  }
  .csn-theme .offset-md-7 {
    margin-left: 58.333333%;
  }
  .csn-theme .offset-md-8 {
    margin-left: 66.666667%;
  }
  .csn-theme .offset-md-9 {
    margin-left: 75%;
  }
  .csn-theme .offset-md-10 {
    margin-left: 83.333333%;
  }
  .csn-theme .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .csn-theme .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .csn-theme .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .csn-theme .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .csn-theme .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .csn-theme .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .csn-theme .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .csn-theme .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .csn-theme .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .csn-theme .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .csn-theme .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .csn-theme .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .csn-theme .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .csn-theme .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .csn-theme .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .csn-theme .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .csn-theme .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .csn-theme .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .csn-theme .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .csn-theme .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .csn-theme .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .csn-theme .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .csn-theme .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .csn-theme .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .csn-theme .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .csn-theme .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .csn-theme .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .csn-theme .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .csn-theme .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .csn-theme .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .csn-theme .offset-lg-0 {
    margin-left: 0;
  }
  .csn-theme .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .csn-theme .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .csn-theme .offset-lg-3 {
    margin-left: 25%;
  }
  .csn-theme .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .csn-theme .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .csn-theme .offset-lg-6 {
    margin-left: 50%;
  }
  .csn-theme .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .csn-theme .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .csn-theme .offset-lg-9 {
    margin-left: 75%;
  }
  .csn-theme .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .csn-theme .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .csn-theme .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .csn-theme .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .csn-theme .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .csn-theme .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .csn-theme .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .csn-theme .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .csn-theme .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .csn-theme .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .csn-theme .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .csn-theme .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .csn-theme .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .csn-theme .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .csn-theme .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .csn-theme .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .csn-theme .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .csn-theme .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .csn-theme .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .csn-theme .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .csn-theme .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .csn-theme .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .csn-theme .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .csn-theme .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .csn-theme .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .csn-theme .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .csn-theme .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .csn-theme .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .csn-theme .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .csn-theme .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .csn-theme .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .csn-theme .offset-xl-0 {
    margin-left: 0;
  }
  .csn-theme .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .csn-theme .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .csn-theme .offset-xl-3 {
    margin-left: 25%;
  }
  .csn-theme .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .csn-theme .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .csn-theme .offset-xl-6 {
    margin-left: 50%;
  }
  .csn-theme .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .csn-theme .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .csn-theme .offset-xl-9 {
    margin-left: 75%;
  }
  .csn-theme .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .csn-theme .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.csn-theme .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
}

.csn-theme .table th,
.csn-theme .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.csn-theme .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.csn-theme .table tbody + tbody {
  border-top: 2px solid #ddd;
}

.csn-theme .table .table {
  background-color: #fff;
}

.csn-theme .table-sm th,
.csn-theme .table-sm td {
  padding: 0.3rem;
}

.csn-theme .table-bordered {
  border: 1px solid #ddd;
}

.csn-theme .table-bordered th,
.csn-theme .table-bordered td {
  border: 1px solid #ddd;
}

.csn-theme .table-bordered thead th,
.csn-theme .table-bordered thead td {
  border-bottom-width: 2px;
}

.csn-theme .table-borderless th,
.csn-theme .table-borderless td,
.csn-theme .table-borderless thead th,
.csn-theme .table-borderless tbody + tbody {
  border: 0;
}

.csn-theme .table-striped tbody tr:nth-of-type(odd) {
  background-color: #f6f6f6;
}

.csn-theme .table-hover tbody tr:hover {
  background-color: #e9e9e9;
}

.csn-theme .table-primary,
.csn-theme .table-primary > th,
.csn-theme .table-primary > td {
  background-color: #cde5c5;
}

.csn-theme .table-hover .table-primary:hover {
  background-color: #beddb3;
}

.csn-theme .table-hover .table-primary:hover > td,
.csn-theme .table-hover .table-primary:hover > th {
  background-color: #beddb3;
}

.csn-theme .table-primary-dark,
.csn-theme .table-primary-dark > th,
.csn-theme .table-primary-dark > td {
  background-color: #c2d6cb;
}

.csn-theme .table-hover .table-primary-dark:hover {
  background-color: #b3ccbe;
}

.csn-theme .table-hover .table-primary-dark:hover > td,
.csn-theme .table-hover .table-primary-dark:hover > th {
  background-color: #b3ccbe;
}

.csn-theme .table-secondary,
.csn-theme .table-secondary > th,
.csn-theme .table-secondary > td {
  background-color: #ffdab8;
}

.csn-theme .table-hover .table-secondary:hover {
  background-color: #ffcd9f;
}

.csn-theme .table-hover .table-secondary:hover > td,
.csn-theme .table-hover .table-secondary:hover > th {
  background-color: #ffcd9f;
}

.csn-theme .table-success,
.csn-theme .table-success > th,
.csn-theme .table-success > td {
  background-color: #caf5e4;
}

.csn-theme .table-hover .table-success:hover {
  background-color: #b5f1d9;
}

.csn-theme .table-hover .table-success:hover > td,
.csn-theme .table-hover .table-success:hover > th {
  background-color: #b5f1d9;
}

.csn-theme .table-info,
.csn-theme .table-info > th,
.csn-theme .table-info > td {
  background-color: #b8daee;
}

.csn-theme .table-hover .table-info:hover {
  background-color: #a3cfe9;
}

.csn-theme .table-hover .table-info:hover > td,
.csn-theme .table-hover .table-info:hover > th {
  background-color: #a3cfe9;
}

.csn-theme .table-warning,
.csn-theme .table-warning > th,
.csn-theme .table-warning > td {
  background-color: #fabfc2;
}

.csn-theme .table-hover .table-warning:hover {
  background-color: #f8a7ab;
}

.csn-theme .table-hover .table-warning:hover > td,
.csn-theme .table-hover .table-warning:hover > th {
  background-color: #f8a7ab;
}

.csn-theme .table-danger,
.csn-theme .table-danger > th,
.csn-theme .table-danger > td {
  background-color: #fabfc2;
}

.csn-theme .table-hover .table-danger:hover {
  background-color: #f8a7ab;
}

.csn-theme .table-hover .table-danger:hover > td,
.csn-theme .table-hover .table-danger:hover > th {
  background-color: #f8a7ab;
}

.csn-theme .table-light,
.csn-theme .table-light > th,
.csn-theme .table-light > td {
  background-color: #fcfcfc;
}

.csn-theme .table-hover .table-light:hover {
  background-color: #efefef;
}

.csn-theme .table-hover .table-light:hover > td,
.csn-theme .table-hover .table-light:hover > th {
  background-color: #efefef;
}

.csn-theme .table-dark,
.csn-theme .table-dark > th,
.csn-theme .table-dark > td {
  background-color: #cccccc;
}

.csn-theme .table-hover .table-dark:hover {
  background-color: #bfbfbf;
}

.csn-theme .table-hover .table-dark:hover > td,
.csn-theme .table-hover .table-dark:hover > th {
  background-color: #bfbfbf;
}

.csn-theme .table-active,
.csn-theme .table-active > th,
.csn-theme .table-active > td {
  background-color: #e9e9e9;
}

.csn-theme .table-hover .table-active:hover {
  background-color: gainsboro;
}

.csn-theme .table-hover .table-active:hover > td,
.csn-theme .table-hover .table-active:hover > th {
  background-color: gainsboro;
}

.csn-theme .table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.csn-theme .table .thead-light th {
  color: #6e6e6e;
  background-color: #f2f2f2;
  border-color: #ddd;
}

.csn-theme .table-dark {
  color: #fff;
  background-color: #212529;
}

.csn-theme .table-dark th,
.csn-theme .table-dark td,
.csn-theme .table-dark thead th {
  border-color: #32383e;
}

.csn-theme .table-dark.table-bordered {
  border: 0;
}

.csn-theme .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.csn-theme .table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .csn-theme .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .csn-theme .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .csn-theme .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .csn-theme .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .csn-theme .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .csn-theme .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .csn-theme .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .csn-theme .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.csn-theme .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.csn-theme .table-responsive > .table-bordered {
  border: 0;
}

.csn-theme .btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 10rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .btn {
    transition: none;
  }
}

.csn-theme .btn:hover, .csn-theme .btn:focus {
  text-decoration: none;
}

.csn-theme .btn:focus, .csn-theme .btn.focus {
  outline: 0;
}

.csn-theme .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.csn-theme .btn:not(:disabled):not(.disabled):active, .csn-theme .btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

.csn-theme a.btn.disabled,
.csn-theme fieldset:disabled a.btn {
  pointer-events: none;
}

.csn-theme .btn-primary {
  color: #fff;
  background-color: #4da32f;
  border-color: #4da32f;
}

.csn-theme .btn-primary:hover {
  color: #fff;
  background-color: #448f29;
  border-color: #448f29;
}

.csn-theme .btn-primary:focus, .csn-theme .btn-primary.focus {
  box-shadow: none;
}

.csn-theme .btn-primary.disabled, .csn-theme .btn-primary:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-primary.btn-loading {
  color: transparent;
}

.csn-theme .btn-primary.btn-loading::after, .csn-theme .btn-primary.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-primary:not(:disabled):not(.disabled):active, .csn-theme .btn-primary:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-primary.dropdown-toggle {
  background-color: #3a7b24;
  border-color: #367221;
}

.csn-theme .btn-primary:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-primary-dark {
  color: #fff;
  background-color: #266e44;
  border-color: #266e44;
}

.csn-theme .btn-primary-dark:hover {
  color: #fff;
  background-color: #1f5b38;
  border-color: #1f5b38;
}

.csn-theme .btn-primary-dark:focus, .csn-theme .btn-primary-dark.focus {
  box-shadow: none;
}

.csn-theme .btn-primary-dark.disabled, .csn-theme .btn-primary-dark:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-primary-dark.btn-loading {
  color: transparent;
}

.csn-theme .btn-primary-dark.btn-loading::after, .csn-theme .btn-primary-dark.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-primary-dark:not(:disabled):not(.disabled):active, .csn-theme .btn-primary-dark:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-primary-dark.dropdown-toggle {
  background-color: #19482d;
  border-color: #163f27;
}

.csn-theme .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-primary-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-secondary {
  color: #fff;
  background-color: #FF7B00;
  border-color: #FF7B00;
}

.csn-theme .btn-secondary:hover {
  color: #fff;
  background-color: #e66f00;
  border-color: #e66f00;
}

.csn-theme .btn-secondary:focus, .csn-theme .btn-secondary.focus {
  box-shadow: none;
}

.csn-theme .btn-secondary.disabled, .csn-theme .btn-secondary:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-secondary.btn-loading {
  color: transparent;
}

.csn-theme .btn-secondary.btn-loading::after, .csn-theme .btn-secondary.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-secondary:not(:disabled):not(.disabled):active, .csn-theme .btn-secondary:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-secondary.dropdown-toggle {
  background-color: #cc6200;
  border-color: #bf5c00;
}

.csn-theme .btn-secondary:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-success {
  color: #212529;
  background-color: #41DBA0;
  border-color: #41DBA0;
}

.csn-theme .btn-success:hover {
  color: #212529;
  background-color: #2cd795;
  border-color: #2cd795;
}

.csn-theme .btn-success:focus, .csn-theme .btn-success.focus {
  box-shadow: none;
}

.csn-theme .btn-success.disabled, .csn-theme .btn-success:disabled {
  color: #212529;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-success.btn-loading {
  color: transparent;
}

.csn-theme .btn-success.btn-loading::after, .csn-theme .btn-success.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-success:not(:disabled):not(.disabled):active, .csn-theme .btn-success:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-success.dropdown-toggle {
  background-color: #25c487;
  border-color: #23b980;
}

.csn-theme .btn-success:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-info {
  color: #fff;
  background-color: #007CC2;
  border-color: #007CC2;
}

.csn-theme .btn-info:hover {
  color: #fff;
  background-color: #006ca9;
  border-color: #006ca9;
}

.csn-theme .btn-info:focus, .csn-theme .btn-info.focus {
  box-shadow: none;
}

.csn-theme .btn-info.disabled, .csn-theme .btn-info:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-info.btn-loading {
  color: transparent;
}

.csn-theme .btn-info.btn-loading::after, .csn-theme .btn-info.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-info:not(:disabled):not(.disabled):active, .csn-theme .btn-info:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-info.dropdown-toggle {
  background-color: #005b8f;
  border-color: #005382;
}

.csn-theme .btn-info:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-warning {
  color: #fff;
  background-color: #ED1C24;
  border-color: #ED1C24;
}

.csn-theme .btn-warning:hover {
  color: #fff;
  background-color: #de1219;
  border-color: #de1219;
}

.csn-theme .btn-warning:focus, .csn-theme .btn-warning.focus {
  box-shadow: none;
}

.csn-theme .btn-warning.disabled, .csn-theme .btn-warning:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-warning.btn-loading {
  color: transparent;
}

.csn-theme .btn-warning.btn-loading::after, .csn-theme .btn-warning.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-warning:not(:disabled):not(.disabled):active, .csn-theme .btn-warning:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-warning.dropdown-toggle {
  background-color: #c61017;
  border-color: #ba0f15;
}

.csn-theme .btn-warning:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-danger {
  color: #fff;
  background-color: #ED1C24;
  border-color: #ED1C24;
}

.csn-theme .btn-danger:hover {
  color: #fff;
  background-color: #de1219;
  border-color: #de1219;
}

.csn-theme .btn-danger:focus, .csn-theme .btn-danger.focus {
  box-shadow: none;
}

.csn-theme .btn-danger.disabled, .csn-theme .btn-danger:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-danger.btn-loading {
  color: transparent;
}

.csn-theme .btn-danger.btn-loading::after, .csn-theme .btn-danger.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-danger:not(:disabled):not(.disabled):active, .csn-theme .btn-danger:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-danger.dropdown-toggle {
  background-color: #c61017;
  border-color: #ba0f15;
}

.csn-theme .btn-danger:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-light {
  color: #212529;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.csn-theme .btn-light:hover {
  color: #212529;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.csn-theme .btn-light:focus, .csn-theme .btn-light.focus {
  box-shadow: none;
}

.csn-theme .btn-light.disabled, .csn-theme .btn-light:disabled {
  color: #212529;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-light.btn-loading {
  color: transparent;
}

.csn-theme .btn-light.btn-loading::after, .csn-theme .btn-light.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-light:not(:disabled):not(.disabled):active, .csn-theme .btn-light:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-light.dropdown-toggle {
  background-color: #dddddd;
  border-color: #d6d6d6;
}

.csn-theme .btn-light:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-dark {
  color: #fff;
  background-color: #4a4a4a;
  border-color: #4a4a4a;
}

.csn-theme .btn-dark:hover {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.csn-theme .btn-dark:focus, .csn-theme .btn-dark.focus {
  box-shadow: none;
}

.csn-theme .btn-dark.disabled, .csn-theme .btn-dark:disabled {
  color: #fff;
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}

.csn-theme .btn-dark.btn-loading {
  color: transparent;
}

.csn-theme .btn-dark.btn-loading::after, .csn-theme .btn-dark.btn-loading::before {
  border-top-color: #fff;
  border-right-color: #fff;
}

.csn-theme .btn-dark:not(:disabled):not(.disabled):active, .csn-theme .btn-dark:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-dark.dropdown-toggle {
  background-color: #313131;
  border-color: #2a2a2a;
}

.csn-theme .btn-dark:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-primary {
  color: #4da32f;
  background-color: transparent;
  background-image: none;
  border-color: #4da32f;
}

.csn-theme .btn-outline-primary:hover {
  color: #4da32f;
  background-color: rgba(77, 163, 47, 0.05);
}

.csn-theme .btn-outline-primary:focus, .csn-theme .btn-outline-primary.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-primary.disabled, .csn-theme .btn-outline-primary:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-primary.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-primary.btn-loading::after, .csn-theme .btn-outline-primary.btn-loading::before {
  border-top-color: #4da32f;
  border-right-color: #4da32f;
}

.csn-theme .btn-outline-primary:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-primary.dropdown-toggle {
  background-color: rgba(77, 163, 47, 0.1);
}

.csn-theme .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-primary-dark {
  color: #266e44;
  background-color: transparent;
  background-image: none;
  border-color: #266e44;
}

.csn-theme .btn-outline-primary-dark:hover {
  color: #266e44;
  background-color: rgba(38, 110, 68, 0.05);
}

.csn-theme .btn-outline-primary-dark:focus, .csn-theme .btn-outline-primary-dark.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-primary-dark.disabled, .csn-theme .btn-outline-primary-dark:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-primary-dark.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-primary-dark.btn-loading::after, .csn-theme .btn-outline-primary-dark.btn-loading::before {
  border-top-color: #266e44;
  border-right-color: #266e44;
}

.csn-theme .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-primary-dark.dropdown-toggle {
  background-color: rgba(38, 110, 68, 0.1);
}

.csn-theme .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-primary-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-secondary {
  color: #FF7B00;
  background-color: transparent;
  background-image: none;
  border-color: #FF7B00;
}

.csn-theme .btn-outline-secondary:hover {
  color: #FF7B00;
  background-color: rgba(255, 123, 0, 0.05);
}

.csn-theme .btn-outline-secondary:focus, .csn-theme .btn-outline-secondary.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-secondary.disabled, .csn-theme .btn-outline-secondary:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-secondary.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-secondary.btn-loading::after, .csn-theme .btn-outline-secondary.btn-loading::before {
  border-top-color: #FF7B00;
  border-right-color: #FF7B00;
}

.csn-theme .btn-outline-secondary:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-secondary.dropdown-toggle {
  background-color: rgba(255, 123, 0, 0.1);
}

.csn-theme .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-success {
  color: #41DBA0;
  background-color: transparent;
  background-image: none;
  border-color: #41DBA0;
}

.csn-theme .btn-outline-success:hover {
  color: #41DBA0;
  background-color: rgba(65, 219, 160, 0.05);
}

.csn-theme .btn-outline-success:focus, .csn-theme .btn-outline-success.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-success.disabled, .csn-theme .btn-outline-success:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-success.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-success.btn-loading::after, .csn-theme .btn-outline-success.btn-loading::before {
  border-top-color: #41DBA0;
  border-right-color: #41DBA0;
}

.csn-theme .btn-outline-success:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-success.dropdown-toggle {
  background-color: rgba(65, 219, 160, 0.1);
}

.csn-theme .btn-outline-success:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-info {
  color: #007CC2;
  background-color: transparent;
  background-image: none;
  border-color: #007CC2;
}

.csn-theme .btn-outline-info:hover {
  color: #007CC2;
  background-color: rgba(0, 124, 194, 0.05);
}

.csn-theme .btn-outline-info:focus, .csn-theme .btn-outline-info.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-info.disabled, .csn-theme .btn-outline-info:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-info.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-info.btn-loading::after, .csn-theme .btn-outline-info.btn-loading::before {
  border-top-color: #007CC2;
  border-right-color: #007CC2;
}

.csn-theme .btn-outline-info:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-info.dropdown-toggle {
  background-color: rgba(0, 124, 194, 0.1);
}

.csn-theme .btn-outline-info:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-warning {
  color: #ED1C24;
  background-color: transparent;
  background-image: none;
  border-color: #ED1C24;
}

.csn-theme .btn-outline-warning:hover {
  color: #ED1C24;
  background-color: rgba(237, 28, 36, 0.05);
}

.csn-theme .btn-outline-warning:focus, .csn-theme .btn-outline-warning.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-warning.disabled, .csn-theme .btn-outline-warning:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-warning.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-warning.btn-loading::after, .csn-theme .btn-outline-warning.btn-loading::before {
  border-top-color: #ED1C24;
  border-right-color: #ED1C24;
}

.csn-theme .btn-outline-warning:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-warning.dropdown-toggle {
  background-color: rgba(237, 28, 36, 0.1);
}

.csn-theme .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-danger {
  color: #ED1C24;
  background-color: transparent;
  background-image: none;
  border-color: #ED1C24;
}

.csn-theme .btn-outline-danger:hover {
  color: #ED1C24;
  background-color: rgba(237, 28, 36, 0.05);
}

.csn-theme .btn-outline-danger:focus, .csn-theme .btn-outline-danger.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-danger.disabled, .csn-theme .btn-outline-danger:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-danger.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-danger.btn-loading::after, .csn-theme .btn-outline-danger.btn-loading::before {
  border-top-color: #ED1C24;
  border-right-color: #ED1C24;
}

.csn-theme .btn-outline-danger:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-danger.dropdown-toggle {
  background-color: rgba(237, 28, 36, 0.1);
}

.csn-theme .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-light {
  color: #f6f6f6;
  background-color: transparent;
  background-image: none;
  border-color: #f6f6f6;
}

.csn-theme .btn-outline-light:hover {
  color: #f6f6f6;
  background-color: rgba(246, 246, 246, 0.05);
}

.csn-theme .btn-outline-light:focus, .csn-theme .btn-outline-light.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-light.disabled, .csn-theme .btn-outline-light:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-light.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-light.btn-loading::after, .csn-theme .btn-outline-light.btn-loading::before {
  border-top-color: #f6f6f6;
  border-right-color: #f6f6f6;
}

.csn-theme .btn-outline-light:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-light.dropdown-toggle {
  background-color: rgba(246, 246, 246, 0.1);
}

.csn-theme .btn-outline-light:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-light.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-outline-dark {
  color: #4a4a4a;
  background-color: transparent;
  background-image: none;
  border-color: #4a4a4a;
}

.csn-theme .btn-outline-dark:hover {
  color: #4a4a4a;
  background-color: rgba(74, 74, 74, 0.05);
}

.csn-theme .btn-outline-dark:focus, .csn-theme .btn-outline-dark.focus {
  box-shadow: none;
}

.csn-theme .btn-outline-dark.disabled, .csn-theme .btn-outline-dark:disabled {
  color: #bfbfbf;
  background-color: transparent;
  border-color: #bfbfbf;
}

.csn-theme .btn-outline-dark.btn-loading {
  color: transparent;
}

.csn-theme .btn-outline-dark.btn-loading::after, .csn-theme .btn-outline-dark.btn-loading::before {
  border-top-color: #4a4a4a;
  border-right-color: #4a4a4a;
}

.csn-theme .btn-outline-dark:not(:disabled):not(.disabled):active, .csn-theme .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .csn-theme .btn-outline-dark.dropdown-toggle {
  background-color: rgba(74, 74, 74, 0.1);
}

.csn-theme .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .csn-theme .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .csn-theme .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.csn-theme .btn-link {
  font-weight: 400;
  color: #266e44;
  background-color: transparent;
}

.csn-theme .btn-link:hover {
  color: #266e44;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.csn-theme .btn-link:focus, .csn-theme .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
}

.csn-theme .btn-link:disabled, .csn-theme .btn-link.disabled {
  color: #bfbfbf;
}

.csn-theme .btn-lg, .csn-theme .btn-group-lg > .btn {
  padding: 0.5rem 2rem;
  font-size: 1.09375rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 10rem;
}

.csn-theme .btn-sm, .csn-theme .btn-group-sm > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.765625rem;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 10rem;
}

.csn-theme .btn-loading {
  position: relative;
}

.csn-theme .btn-loading::after, .csn-theme .btn-loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  margin-top: -.5rem;
  margin-left: -.5rem;
  content: "";
  border: 2px solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner .6s ease infinite;
  animation: spinner .6s ease infinite;
}

.csn-theme .btn-loading::before {
  -webkit-animation-delay: .1s;
  animation-delay: .1s;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.csn-theme .btn-block {
  display: block;
  width: 100%;
}

.csn-theme .btn-block + .btn-block {
  margin-top: 0.5rem;
}

.csn-theme input[type="submit"].btn-block,
.csn-theme input[type="reset"].btn-block,
.csn-theme input[type="button"].btn-block {
  width: 100%;
}

.csn-theme .fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .fade {
    transition: none;
  }
}

.csn-theme .fade:not(.show) {
  opacity: 0;
}

.csn-theme .collapse:not(.show) {
  display: none;
}

.csn-theme .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .collapsing {
    transition: none;
  }
}

.csn-theme .badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.csn-theme .badge:empty {
  display: none;
}

.csn-theme .btn .badge {
  position: relative;
  top: -1px;
}

.csn-theme .badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.csn-theme .badge-primary {
  color: #fff;
  background-color: #4da32f;
}

.csn-theme .badge-primary[href]:hover, .csn-theme .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3a7b24;
}

.csn-theme .badge-primary-dark {
  color: #fff;
  background-color: #266e44;
}

.csn-theme .badge-primary-dark[href]:hover, .csn-theme .badge-primary-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #19482d;
}

.csn-theme .badge-secondary {
  color: #fff;
  background-color: #FF7B00;
}

.csn-theme .badge-secondary[href]:hover, .csn-theme .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #cc6200;
}

.csn-theme .badge-success {
  color: #212529;
  background-color: #41DBA0;
}

.csn-theme .badge-success[href]:hover, .csn-theme .badge-success[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #25c487;
}

.csn-theme .badge-info {
  color: #fff;
  background-color: #007CC2;
}

.csn-theme .badge-info[href]:hover, .csn-theme .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #005b8f;
}

.csn-theme .badge-warning {
  color: #fff;
  background-color: #ED1C24;
}

.csn-theme .badge-warning[href]:hover, .csn-theme .badge-warning[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #c61017;
}

.csn-theme .badge-danger {
  color: #fff;
  background-color: #ED1C24;
}

.csn-theme .badge-danger[href]:hover, .csn-theme .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #c61017;
}

.csn-theme .badge-light {
  color: #212529;
  background-color: #f6f6f6;
}

.csn-theme .badge-light[href]:hover, .csn-theme .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dddddd;
}

.csn-theme .badge-dark {
  color: #fff;
  background-color: #4a4a4a;
}

.csn-theme .badge-dark[href]:hover, .csn-theme .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #313131;
}

.csn-theme .align-baseline {
  vertical-align: baseline !important;
}

.csn-theme .align-top {
  vertical-align: top !important;
}

.csn-theme .align-middle {
  vertical-align: middle !important;
}

.csn-theme .align-bottom {
  vertical-align: bottom !important;
}

.csn-theme .align-text-bottom {
  vertical-align: text-bottom !important;
}

.csn-theme .align-text-top {
  vertical-align: text-top !important;
}

.csn-theme .bg-primary {
  background-color: #4da32f !important;
}

.csn-theme a.bg-primary:hover, .csn-theme a.bg-primary:focus,
.csn-theme button.bg-primary:hover,
.csn-theme button.bg-primary:focus {
  background-color: #3a7b24 !important;
}

.csn-theme .bg-primary-dark {
  background-color: #266e44 !important;
}

.csn-theme a.bg-primary-dark:hover, .csn-theme a.bg-primary-dark:focus,
.csn-theme button.bg-primary-dark:hover,
.csn-theme button.bg-primary-dark:focus {
  background-color: #19482d !important;
}

.csn-theme .bg-secondary {
  background-color: #FF7B00 !important;
}

.csn-theme a.bg-secondary:hover, .csn-theme a.bg-secondary:focus,
.csn-theme button.bg-secondary:hover,
.csn-theme button.bg-secondary:focus {
  background-color: #cc6200 !important;
}

.csn-theme .bg-success {
  background-color: #41DBA0 !important;
}

.csn-theme a.bg-success:hover, .csn-theme a.bg-success:focus,
.csn-theme button.bg-success:hover,
.csn-theme button.bg-success:focus {
  background-color: #25c487 !important;
}

.csn-theme .bg-info {
  background-color: #007CC2 !important;
}

.csn-theme a.bg-info:hover, .csn-theme a.bg-info:focus,
.csn-theme button.bg-info:hover,
.csn-theme button.bg-info:focus {
  background-color: #005b8f !important;
}

.csn-theme .bg-warning {
  background-color: #ED1C24 !important;
}

.csn-theme a.bg-warning:hover, .csn-theme a.bg-warning:focus,
.csn-theme button.bg-warning:hover,
.csn-theme button.bg-warning:focus {
  background-color: #c61017 !important;
}

.csn-theme .bg-danger {
  background-color: #ED1C24 !important;
}

.csn-theme a.bg-danger:hover, .csn-theme a.bg-danger:focus,
.csn-theme button.bg-danger:hover,
.csn-theme button.bg-danger:focus {
  background-color: #c61017 !important;
}

.csn-theme .bg-light {
  background-color: #f6f6f6 !important;
}

.csn-theme a.bg-light:hover, .csn-theme a.bg-light:focus,
.csn-theme button.bg-light:hover,
.csn-theme button.bg-light:focus {
  background-color: #dddddd !important;
}

.csn-theme .bg-dark {
  background-color: #4a4a4a !important;
}

.csn-theme a.bg-dark:hover, .csn-theme a.bg-dark:focus,
.csn-theme button.bg-dark:hover,
.csn-theme button.bg-dark:focus {
  background-color: #313131 !important;
}

.csn-theme .bg-white {
  background-color: #fff !important;
}

.csn-theme .bg-transparent {
  background-color: transparent !important;
}

.csn-theme .border {
  border: 1px solid #ddd !important;
}

.csn-theme .border-top {
  border-top: 1px solid #ddd !important;
}

.csn-theme .border-right {
  border-right: 1px solid #ddd !important;
}

.csn-theme .border-bottom {
  border-bottom: 1px solid #ddd !important;
}

.csn-theme .border-left {
  border-left: 1px solid #ddd !important;
}

.csn-theme .border-0 {
  border: 0 !important;
}

.csn-theme .border-top-0 {
  border-top: 0 !important;
}

.csn-theme .border-right-0 {
  border-right: 0 !important;
}

.csn-theme .border-bottom-0 {
  border-bottom: 0 !important;
}

.csn-theme .border-left-0 {
  border-left: 0 !important;
}

.csn-theme .border-primary {
  border-color: #4da32f !important;
}

.csn-theme .border-primary-dark {
  border-color: #266e44 !important;
}

.csn-theme .border-secondary {
  border-color: #FF7B00 !important;
}

.csn-theme .border-success {
  border-color: #41DBA0 !important;
}

.csn-theme .border-info {
  border-color: #007CC2 !important;
}

.csn-theme .border-warning {
  border-color: #ED1C24 !important;
}

.csn-theme .border-danger {
  border-color: #ED1C24 !important;
}

.csn-theme .border-light {
  border-color: #f6f6f6 !important;
}

.csn-theme .border-dark {
  border-color: #4a4a4a !important;
}

.csn-theme .border-white {
  border-color: #fff !important;
}

.csn-theme .rounded {
  border-radius: 0.25rem !important;
}

.csn-theme .rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.csn-theme .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.csn-theme .rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.csn-theme .rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.csn-theme .rounded-circle {
  border-radius: 50% !important;
}

.csn-theme .rounded-0 {
  border-radius: 0 !important;
}

.csn-theme .clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.csn-theme .d-none {
  display: none !important;
}

.csn-theme .d-inline {
  display: inline !important;
}

.csn-theme .d-inline-block {
  display: inline-block !important;
}

.csn-theme .d-block {
  display: block !important;
}

.csn-theme .d-table {
  display: table !important;
}

.csn-theme .d-table-row {
  display: table-row !important;
}

.csn-theme .d-table-cell {
  display: table-cell !important;
}

.csn-theme .d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.csn-theme .d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .csn-theme .d-sm-none {
    display: none !important;
  }
  .csn-theme .d-sm-inline {
    display: inline !important;
  }
  .csn-theme .d-sm-inline-block {
    display: inline-block !important;
  }
  .csn-theme .d-sm-block {
    display: block !important;
  }
  .csn-theme .d-sm-table {
    display: table !important;
  }
  .csn-theme .d-sm-table-row {
    display: table-row !important;
  }
  .csn-theme .d-sm-table-cell {
    display: table-cell !important;
  }
  .csn-theme .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .csn-theme .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .csn-theme .d-md-none {
    display: none !important;
  }
  .csn-theme .d-md-inline {
    display: inline !important;
  }
  .csn-theme .d-md-inline-block {
    display: inline-block !important;
  }
  .csn-theme .d-md-block {
    display: block !important;
  }
  .csn-theme .d-md-table {
    display: table !important;
  }
  .csn-theme .d-md-table-row {
    display: table-row !important;
  }
  .csn-theme .d-md-table-cell {
    display: table-cell !important;
  }
  .csn-theme .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .csn-theme .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .csn-theme .d-lg-none {
    display: none !important;
  }
  .csn-theme .d-lg-inline {
    display: inline !important;
  }
  .csn-theme .d-lg-inline-block {
    display: inline-block !important;
  }
  .csn-theme .d-lg-block {
    display: block !important;
  }
  .csn-theme .d-lg-table {
    display: table !important;
  }
  .csn-theme .d-lg-table-row {
    display: table-row !important;
  }
  .csn-theme .d-lg-table-cell {
    display: table-cell !important;
  }
  .csn-theme .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .csn-theme .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .csn-theme .d-xl-none {
    display: none !important;
  }
  .csn-theme .d-xl-inline {
    display: inline !important;
  }
  .csn-theme .d-xl-inline-block {
    display: inline-block !important;
  }
  .csn-theme .d-xl-block {
    display: block !important;
  }
  .csn-theme .d-xl-table {
    display: table !important;
  }
  .csn-theme .d-xl-table-row {
    display: table-row !important;
  }
  .csn-theme .d-xl-table-cell {
    display: table-cell !important;
  }
  .csn-theme .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .csn-theme .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .csn-theme .d-print-none {
    display: none !important;
  }
  .csn-theme .d-print-inline {
    display: inline !important;
  }
  .csn-theme .d-print-inline-block {
    display: inline-block !important;
  }
  .csn-theme .d-print-block {
    display: block !important;
  }
  .csn-theme .d-print-table {
    display: table !important;
  }
  .csn-theme .d-print-table-row {
    display: table-row !important;
  }
  .csn-theme .d-print-table-cell {
    display: table-cell !important;
  }
  .csn-theme .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .csn-theme .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.csn-theme .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.csn-theme .embed-responsive::before {
  display: block;
  content: "";
}

.csn-theme .embed-responsive .embed-responsive-item,
.csn-theme .embed-responsive iframe,
.csn-theme .embed-responsive embed,
.csn-theme .embed-responsive object,
.csn-theme .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.csn-theme .embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.csn-theme .embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.csn-theme .embed-responsive-4by3::before {
  padding-top: 75%;
}

.csn-theme .embed-responsive-1by1::before {
  padding-top: 100%;
}

.csn-theme .flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.csn-theme .flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.csn-theme .flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.csn-theme .flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.csn-theme .flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.csn-theme .flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.csn-theme .flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.csn-theme .flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.csn-theme .flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.csn-theme .flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.csn-theme .flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.csn-theme .flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.csn-theme .justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.csn-theme .justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.csn-theme .justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.csn-theme .justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.csn-theme .justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.csn-theme .align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.csn-theme .align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.csn-theme .align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.csn-theme .align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.csn-theme .align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.csn-theme .align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.csn-theme .align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.csn-theme .align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.csn-theme .align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.csn-theme .align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.csn-theme .align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.csn-theme .align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.csn-theme .align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.csn-theme .align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.csn-theme .align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.csn-theme .align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.csn-theme .align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .csn-theme .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .csn-theme .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .csn-theme .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .csn-theme .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .csn-theme .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .csn-theme .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .csn-theme .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .csn-theme .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .csn-theme .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .csn-theme .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .csn-theme .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .csn-theme .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .csn-theme .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .csn-theme .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .csn-theme .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .csn-theme .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .csn-theme .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .csn-theme .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .csn-theme .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .csn-theme .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .csn-theme .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .csn-theme .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .csn-theme .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .csn-theme .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .csn-theme .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .csn-theme .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .csn-theme .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .csn-theme .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .csn-theme .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .csn-theme .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .csn-theme .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .csn-theme .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .csn-theme .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .csn-theme .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .csn-theme .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .csn-theme .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .csn-theme .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .csn-theme .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .csn-theme .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .csn-theme .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .csn-theme .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .csn-theme .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .csn-theme .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .csn-theme .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .csn-theme .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .csn-theme .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .csn-theme .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .csn-theme .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .csn-theme .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .csn-theme .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .csn-theme .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .csn-theme .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .csn-theme .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .csn-theme .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .csn-theme .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .csn-theme .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .csn-theme .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .csn-theme .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .csn-theme .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .csn-theme .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .csn-theme .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .csn-theme .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .csn-theme .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .csn-theme .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .csn-theme .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .csn-theme .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .csn-theme .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .csn-theme .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .csn-theme .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .csn-theme .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .csn-theme .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .csn-theme .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .csn-theme .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .csn-theme .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .csn-theme .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .csn-theme .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .csn-theme .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .csn-theme .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .csn-theme .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .csn-theme .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .csn-theme .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .csn-theme .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .csn-theme .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .csn-theme .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .csn-theme .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .csn-theme .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .csn-theme .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .csn-theme .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .csn-theme .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .csn-theme .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .csn-theme .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .csn-theme .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .csn-theme .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .csn-theme .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .csn-theme .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .csn-theme .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .csn-theme .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .csn-theme .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .csn-theme .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .csn-theme .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .csn-theme .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .csn-theme .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .csn-theme .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .csn-theme .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .csn-theme .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .csn-theme .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .csn-theme .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .csn-theme .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .csn-theme .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .csn-theme .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .csn-theme .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .csn-theme .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .csn-theme .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .csn-theme .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .csn-theme .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .csn-theme .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .csn-theme .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .csn-theme .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .csn-theme .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .csn-theme .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .csn-theme .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .csn-theme .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .csn-theme .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .csn-theme .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .csn-theme .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .csn-theme .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .csn-theme .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .csn-theme .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .csn-theme .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .csn-theme .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .csn-theme .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .csn-theme .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .csn-theme .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .csn-theme .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .csn-theme .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .csn-theme .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.csn-theme .float-left {
  float: left !important;
}

.csn-theme .float-right {
  float: right !important;
}

.csn-theme .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .csn-theme .float-sm-left {
    float: left !important;
  }
  .csn-theme .float-sm-right {
    float: right !important;
  }
  .csn-theme .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .csn-theme .float-md-left {
    float: left !important;
  }
  .csn-theme .float-md-right {
    float: right !important;
  }
  .csn-theme .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .csn-theme .float-lg-left {
    float: left !important;
  }
  .csn-theme .float-lg-right {
    float: right !important;
  }
  .csn-theme .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .csn-theme .float-xl-left {
    float: left !important;
  }
  .csn-theme .float-xl-right {
    float: right !important;
  }
  .csn-theme .float-xl-none {
    float: none !important;
  }
}

.csn-theme .position-static {
  position: static !important;
}

.csn-theme .position-relative {
  position: relative !important;
}

.csn-theme .position-absolute {
  position: absolute !important;
}

.csn-theme .position-fixed {
  position: fixed !important;
}

.csn-theme .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.csn-theme .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.csn-theme .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .csn-theme .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.csn-theme .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.csn-theme .sr-only-focusable:active, .csn-theme .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.csn-theme .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.csn-theme .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.csn-theme .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.csn-theme .shadow-none {
  box-shadow: none !important;
}

.csn-theme .w-25 {
  width: 25% !important;
}

.csn-theme .w-50 {
  width: 50% !important;
}

.csn-theme .w-75 {
  width: 75% !important;
}

.csn-theme .w-100 {
  width: 100% !important;
}

.csn-theme .w-auto {
  width: auto !important;
}

.csn-theme .h-25 {
  height: 25% !important;
}

.csn-theme .h-50 {
  height: 50% !important;
}

.csn-theme .h-75 {
  height: 75% !important;
}

.csn-theme .h-100 {
  height: 100% !important;
}

.csn-theme .h-auto {
  height: auto !important;
}

.csn-theme .mw-100 {
  max-width: 100% !important;
}

.csn-theme .mh-100 {
  max-height: 100% !important;
}

.csn-theme .m-0 {
  margin: 0 !important;
}

.csn-theme .mt-0,
.csn-theme .my-0 {
  margin-top: 0 !important;
}

.csn-theme .mr-0,
.csn-theme .mx-0 {
  margin-right: 0 !important;
}

.csn-theme .mb-0,
.csn-theme .my-0 {
  margin-bottom: 0 !important;
}

.csn-theme .ml-0,
.csn-theme .mx-0 {
  margin-left: 0 !important;
}

.csn-theme .m-1 {
  margin: 10px !important;
}

.csn-theme .mt-1,
.csn-theme .my-1 {
  margin-top: 10px !important;
}

.csn-theme .mr-1,
.csn-theme .mx-1 {
  margin-right: 10px !important;
}

.csn-theme .mb-1,
.csn-theme .my-1 {
  margin-bottom: 10px !important;
}

.csn-theme .ml-1,
.csn-theme .mx-1 {
  margin-left: 10px !important;
}

.csn-theme .m-2 {
  margin: 20px !important;
}

.csn-theme .mt-2,
.csn-theme .my-2 {
  margin-top: 20px !important;
}

.csn-theme .mr-2,
.csn-theme .mx-2 {
  margin-right: 20px !important;
}

.csn-theme .mb-2,
.csn-theme .my-2 {
  margin-bottom: 20px !important;
}

.csn-theme .ml-2,
.csn-theme .mx-2 {
  margin-left: 20px !important;
}

.csn-theme .m-3 {
  margin: 30px !important;
}

.csn-theme .mt-3,
.csn-theme .my-3 {
  margin-top: 30px !important;
}

.csn-theme .mr-3,
.csn-theme .mx-3 {
  margin-right: 30px !important;
}

.csn-theme .mb-3,
.csn-theme .my-3 {
  margin-bottom: 30px !important;
}

.csn-theme .ml-3,
.csn-theme .mx-3 {
  margin-left: 30px !important;
}

.csn-theme .m-4 {
  margin: 40px !important;
}

.csn-theme .mt-4,
.csn-theme .my-4 {
  margin-top: 40px !important;
}

.csn-theme .mr-4,
.csn-theme .mx-4 {
  margin-right: 40px !important;
}

.csn-theme .mb-4,
.csn-theme .my-4 {
  margin-bottom: 40px !important;
}

.csn-theme .ml-4,
.csn-theme .mx-4 {
  margin-left: 40px !important;
}

.csn-theme .m-5 {
  margin: 50px !important;
}

.csn-theme .mt-5,
.csn-theme .my-5 {
  margin-top: 50px !important;
}

.csn-theme .mr-5,
.csn-theme .mx-5 {
  margin-right: 50px !important;
}

.csn-theme .mb-5,
.csn-theme .my-5 {
  margin-bottom: 50px !important;
}

.csn-theme .ml-5,
.csn-theme .mx-5 {
  margin-left: 50px !important;
}

.csn-theme .p-0 {
  padding: 0 !important;
}

.csn-theme .pt-0,
.csn-theme .py-0 {
  padding-top: 0 !important;
}

.csn-theme .pr-0,
.csn-theme .px-0 {
  padding-right: 0 !important;
}

.csn-theme .pb-0,
.csn-theme .py-0 {
  padding-bottom: 0 !important;
}

.csn-theme .pl-0,
.csn-theme .px-0 {
  padding-left: 0 !important;
}

.csn-theme .p-1 {
  padding: 10px !important;
}

.csn-theme .pt-1,
.csn-theme .py-1 {
  padding-top: 10px !important;
}

.csn-theme .pr-1,
.csn-theme .px-1 {
  padding-right: 10px !important;
}

.csn-theme .pb-1,
.csn-theme .py-1 {
  padding-bottom: 10px !important;
}

.csn-theme .pl-1,
.csn-theme .px-1 {
  padding-left: 10px !important;
}

.csn-theme .p-2 {
  padding: 20px !important;
}

.csn-theme .pt-2,
.csn-theme .py-2 {
  padding-top: 20px !important;
}

.csn-theme .pr-2,
.csn-theme .px-2 {
  padding-right: 20px !important;
}

.csn-theme .pb-2,
.csn-theme .py-2 {
  padding-bottom: 20px !important;
}

.csn-theme .pl-2,
.csn-theme .px-2 {
  padding-left: 20px !important;
}

.csn-theme .p-3 {
  padding: 30px !important;
}

.csn-theme .pt-3,
.csn-theme .py-3 {
  padding-top: 30px !important;
}

.csn-theme .pr-3,
.csn-theme .px-3 {
  padding-right: 30px !important;
}

.csn-theme .pb-3,
.csn-theme .py-3 {
  padding-bottom: 30px !important;
}

.csn-theme .pl-3,
.csn-theme .px-3 {
  padding-left: 30px !important;
}

.csn-theme .p-4 {
  padding: 40px !important;
}

.csn-theme .pt-4,
.csn-theme .py-4 {
  padding-top: 40px !important;
}

.csn-theme .pr-4,
.csn-theme .px-4 {
  padding-right: 40px !important;
}

.csn-theme .pb-4,
.csn-theme .py-4 {
  padding-bottom: 40px !important;
}

.csn-theme .pl-4,
.csn-theme .px-4 {
  padding-left: 40px !important;
}

.csn-theme .p-5 {
  padding: 50px !important;
}

.csn-theme .pt-5,
.csn-theme .py-5 {
  padding-top: 50px !important;
}

.csn-theme .pr-5,
.csn-theme .px-5 {
  padding-right: 50px !important;
}

.csn-theme .pb-5,
.csn-theme .py-5 {
  padding-bottom: 50px !important;
}

.csn-theme .pl-5,
.csn-theme .px-5 {
  padding-left: 50px !important;
}

.csn-theme .m-auto {
  margin: auto !important;
}

.csn-theme .mt-auto,
.csn-theme .my-auto {
  margin-top: auto !important;
}

.csn-theme .mr-auto,
.csn-theme .mx-auto {
  margin-right: auto !important;
}

.csn-theme .mb-auto,
.csn-theme .my-auto {
  margin-bottom: auto !important;
}

.csn-theme .ml-auto,
.csn-theme .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .csn-theme .m-sm-0 {
    margin: 0 !important;
  }
  .csn-theme .mt-sm-0,
  .csn-theme .my-sm-0 {
    margin-top: 0 !important;
  }
  .csn-theme .mr-sm-0,
  .csn-theme .mx-sm-0 {
    margin-right: 0 !important;
  }
  .csn-theme .mb-sm-0,
  .csn-theme .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .csn-theme .ml-sm-0,
  .csn-theme .mx-sm-0 {
    margin-left: 0 !important;
  }
  .csn-theme .m-sm-1 {
    margin: 10px !important;
  }
  .csn-theme .mt-sm-1,
  .csn-theme .my-sm-1 {
    margin-top: 10px !important;
  }
  .csn-theme .mr-sm-1,
  .csn-theme .mx-sm-1 {
    margin-right: 10px !important;
  }
  .csn-theme .mb-sm-1,
  .csn-theme .my-sm-1 {
    margin-bottom: 10px !important;
  }
  .csn-theme .ml-sm-1,
  .csn-theme .mx-sm-1 {
    margin-left: 10px !important;
  }
  .csn-theme .m-sm-2 {
    margin: 20px !important;
  }
  .csn-theme .mt-sm-2,
  .csn-theme .my-sm-2 {
    margin-top: 20px !important;
  }
  .csn-theme .mr-sm-2,
  .csn-theme .mx-sm-2 {
    margin-right: 20px !important;
  }
  .csn-theme .mb-sm-2,
  .csn-theme .my-sm-2 {
    margin-bottom: 20px !important;
  }
  .csn-theme .ml-sm-2,
  .csn-theme .mx-sm-2 {
    margin-left: 20px !important;
  }
  .csn-theme .m-sm-3 {
    margin: 30px !important;
  }
  .csn-theme .mt-sm-3,
  .csn-theme .my-sm-3 {
    margin-top: 30px !important;
  }
  .csn-theme .mr-sm-3,
  .csn-theme .mx-sm-3 {
    margin-right: 30px !important;
  }
  .csn-theme .mb-sm-3,
  .csn-theme .my-sm-3 {
    margin-bottom: 30px !important;
  }
  .csn-theme .ml-sm-3,
  .csn-theme .mx-sm-3 {
    margin-left: 30px !important;
  }
  .csn-theme .m-sm-4 {
    margin: 40px !important;
  }
  .csn-theme .mt-sm-4,
  .csn-theme .my-sm-4 {
    margin-top: 40px !important;
  }
  .csn-theme .mr-sm-4,
  .csn-theme .mx-sm-4 {
    margin-right: 40px !important;
  }
  .csn-theme .mb-sm-4,
  .csn-theme .my-sm-4 {
    margin-bottom: 40px !important;
  }
  .csn-theme .ml-sm-4,
  .csn-theme .mx-sm-4 {
    margin-left: 40px !important;
  }
  .csn-theme .m-sm-5 {
    margin: 50px !important;
  }
  .csn-theme .mt-sm-5,
  .csn-theme .my-sm-5 {
    margin-top: 50px !important;
  }
  .csn-theme .mr-sm-5,
  .csn-theme .mx-sm-5 {
    margin-right: 50px !important;
  }
  .csn-theme .mb-sm-5,
  .csn-theme .my-sm-5 {
    margin-bottom: 50px !important;
  }
  .csn-theme .ml-sm-5,
  .csn-theme .mx-sm-5 {
    margin-left: 50px !important;
  }
  .csn-theme .p-sm-0 {
    padding: 0 !important;
  }
  .csn-theme .pt-sm-0,
  .csn-theme .py-sm-0 {
    padding-top: 0 !important;
  }
  .csn-theme .pr-sm-0,
  .csn-theme .px-sm-0 {
    padding-right: 0 !important;
  }
  .csn-theme .pb-sm-0,
  .csn-theme .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .csn-theme .pl-sm-0,
  .csn-theme .px-sm-0 {
    padding-left: 0 !important;
  }
  .csn-theme .p-sm-1 {
    padding: 10px !important;
  }
  .csn-theme .pt-sm-1,
  .csn-theme .py-sm-1 {
    padding-top: 10px !important;
  }
  .csn-theme .pr-sm-1,
  .csn-theme .px-sm-1 {
    padding-right: 10px !important;
  }
  .csn-theme .pb-sm-1,
  .csn-theme .py-sm-1 {
    padding-bottom: 10px !important;
  }
  .csn-theme .pl-sm-1,
  .csn-theme .px-sm-1 {
    padding-left: 10px !important;
  }
  .csn-theme .p-sm-2 {
    padding: 20px !important;
  }
  .csn-theme .pt-sm-2,
  .csn-theme .py-sm-2 {
    padding-top: 20px !important;
  }
  .csn-theme .pr-sm-2,
  .csn-theme .px-sm-2 {
    padding-right: 20px !important;
  }
  .csn-theme .pb-sm-2,
  .csn-theme .py-sm-2 {
    padding-bottom: 20px !important;
  }
  .csn-theme .pl-sm-2,
  .csn-theme .px-sm-2 {
    padding-left: 20px !important;
  }
  .csn-theme .p-sm-3 {
    padding: 30px !important;
  }
  .csn-theme .pt-sm-3,
  .csn-theme .py-sm-3 {
    padding-top: 30px !important;
  }
  .csn-theme .pr-sm-3,
  .csn-theme .px-sm-3 {
    padding-right: 30px !important;
  }
  .csn-theme .pb-sm-3,
  .csn-theme .py-sm-3 {
    padding-bottom: 30px !important;
  }
  .csn-theme .pl-sm-3,
  .csn-theme .px-sm-3 {
    padding-left: 30px !important;
  }
  .csn-theme .p-sm-4 {
    padding: 40px !important;
  }
  .csn-theme .pt-sm-4,
  .csn-theme .py-sm-4 {
    padding-top: 40px !important;
  }
  .csn-theme .pr-sm-4,
  .csn-theme .px-sm-4 {
    padding-right: 40px !important;
  }
  .csn-theme .pb-sm-4,
  .csn-theme .py-sm-4 {
    padding-bottom: 40px !important;
  }
  .csn-theme .pl-sm-4,
  .csn-theme .px-sm-4 {
    padding-left: 40px !important;
  }
  .csn-theme .p-sm-5 {
    padding: 50px !important;
  }
  .csn-theme .pt-sm-5,
  .csn-theme .py-sm-5 {
    padding-top: 50px !important;
  }
  .csn-theme .pr-sm-5,
  .csn-theme .px-sm-5 {
    padding-right: 50px !important;
  }
  .csn-theme .pb-sm-5,
  .csn-theme .py-sm-5 {
    padding-bottom: 50px !important;
  }
  .csn-theme .pl-sm-5,
  .csn-theme .px-sm-5 {
    padding-left: 50px !important;
  }
  .csn-theme .m-sm-auto {
    margin: auto !important;
  }
  .csn-theme .mt-sm-auto,
  .csn-theme .my-sm-auto {
    margin-top: auto !important;
  }
  .csn-theme .mr-sm-auto,
  .csn-theme .mx-sm-auto {
    margin-right: auto !important;
  }
  .csn-theme .mb-sm-auto,
  .csn-theme .my-sm-auto {
    margin-bottom: auto !important;
  }
  .csn-theme .ml-sm-auto,
  .csn-theme .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .csn-theme .m-md-0 {
    margin: 0 !important;
  }
  .csn-theme .mt-md-0,
  .csn-theme .my-md-0 {
    margin-top: 0 !important;
  }
  .csn-theme .mr-md-0,
  .csn-theme .mx-md-0 {
    margin-right: 0 !important;
  }
  .csn-theme .mb-md-0,
  .csn-theme .my-md-0 {
    margin-bottom: 0 !important;
  }
  .csn-theme .ml-md-0,
  .csn-theme .mx-md-0 {
    margin-left: 0 !important;
  }
  .csn-theme .m-md-1 {
    margin: 10px !important;
  }
  .csn-theme .mt-md-1,
  .csn-theme .my-md-1 {
    margin-top: 10px !important;
  }
  .csn-theme .mr-md-1,
  .csn-theme .mx-md-1 {
    margin-right: 10px !important;
  }
  .csn-theme .mb-md-1,
  .csn-theme .my-md-1 {
    margin-bottom: 10px !important;
  }
  .csn-theme .ml-md-1,
  .csn-theme .mx-md-1 {
    margin-left: 10px !important;
  }
  .csn-theme .m-md-2 {
    margin: 20px !important;
  }
  .csn-theme .mt-md-2,
  .csn-theme .my-md-2 {
    margin-top: 20px !important;
  }
  .csn-theme .mr-md-2,
  .csn-theme .mx-md-2 {
    margin-right: 20px !important;
  }
  .csn-theme .mb-md-2,
  .csn-theme .my-md-2 {
    margin-bottom: 20px !important;
  }
  .csn-theme .ml-md-2,
  .csn-theme .mx-md-2 {
    margin-left: 20px !important;
  }
  .csn-theme .m-md-3 {
    margin: 30px !important;
  }
  .csn-theme .mt-md-3,
  .csn-theme .my-md-3 {
    margin-top: 30px !important;
  }
  .csn-theme .mr-md-3,
  .csn-theme .mx-md-3 {
    margin-right: 30px !important;
  }
  .csn-theme .mb-md-3,
  .csn-theme .my-md-3 {
    margin-bottom: 30px !important;
  }
  .csn-theme .ml-md-3,
  .csn-theme .mx-md-3 {
    margin-left: 30px !important;
  }
  .csn-theme .m-md-4 {
    margin: 40px !important;
  }
  .csn-theme .mt-md-4,
  .csn-theme .my-md-4 {
    margin-top: 40px !important;
  }
  .csn-theme .mr-md-4,
  .csn-theme .mx-md-4 {
    margin-right: 40px !important;
  }
  .csn-theme .mb-md-4,
  .csn-theme .my-md-4 {
    margin-bottom: 40px !important;
  }
  .csn-theme .ml-md-4,
  .csn-theme .mx-md-4 {
    margin-left: 40px !important;
  }
  .csn-theme .m-md-5 {
    margin: 50px !important;
  }
  .csn-theme .mt-md-5,
  .csn-theme .my-md-5 {
    margin-top: 50px !important;
  }
  .csn-theme .mr-md-5,
  .csn-theme .mx-md-5 {
    margin-right: 50px !important;
  }
  .csn-theme .mb-md-5,
  .csn-theme .my-md-5 {
    margin-bottom: 50px !important;
  }
  .csn-theme .ml-md-5,
  .csn-theme .mx-md-5 {
    margin-left: 50px !important;
  }
  .csn-theme .p-md-0 {
    padding: 0 !important;
  }
  .csn-theme .pt-md-0,
  .csn-theme .py-md-0 {
    padding-top: 0 !important;
  }
  .csn-theme .pr-md-0,
  .csn-theme .px-md-0 {
    padding-right: 0 !important;
  }
  .csn-theme .pb-md-0,
  .csn-theme .py-md-0 {
    padding-bottom: 0 !important;
  }
  .csn-theme .pl-md-0,
  .csn-theme .px-md-0 {
    padding-left: 0 !important;
  }
  .csn-theme .p-md-1 {
    padding: 10px !important;
  }
  .csn-theme .pt-md-1,
  .csn-theme .py-md-1 {
    padding-top: 10px !important;
  }
  .csn-theme .pr-md-1,
  .csn-theme .px-md-1 {
    padding-right: 10px !important;
  }
  .csn-theme .pb-md-1,
  .csn-theme .py-md-1 {
    padding-bottom: 10px !important;
  }
  .csn-theme .pl-md-1,
  .csn-theme .px-md-1 {
    padding-left: 10px !important;
  }
  .csn-theme .p-md-2 {
    padding: 20px !important;
  }
  .csn-theme .pt-md-2,
  .csn-theme .py-md-2 {
    padding-top: 20px !important;
  }
  .csn-theme .pr-md-2,
  .csn-theme .px-md-2 {
    padding-right: 20px !important;
  }
  .csn-theme .pb-md-2,
  .csn-theme .py-md-2 {
    padding-bottom: 20px !important;
  }
  .csn-theme .pl-md-2,
  .csn-theme .px-md-2 {
    padding-left: 20px !important;
  }
  .csn-theme .p-md-3 {
    padding: 30px !important;
  }
  .csn-theme .pt-md-3,
  .csn-theme .py-md-3 {
    padding-top: 30px !important;
  }
  .csn-theme .pr-md-3,
  .csn-theme .px-md-3 {
    padding-right: 30px !important;
  }
  .csn-theme .pb-md-3,
  .csn-theme .py-md-3 {
    padding-bottom: 30px !important;
  }
  .csn-theme .pl-md-3,
  .csn-theme .px-md-3 {
    padding-left: 30px !important;
  }
  .csn-theme .p-md-4 {
    padding: 40px !important;
  }
  .csn-theme .pt-md-4,
  .csn-theme .py-md-4 {
    padding-top: 40px !important;
  }
  .csn-theme .pr-md-4,
  .csn-theme .px-md-4 {
    padding-right: 40px !important;
  }
  .csn-theme .pb-md-4,
  .csn-theme .py-md-4 {
    padding-bottom: 40px !important;
  }
  .csn-theme .pl-md-4,
  .csn-theme .px-md-4 {
    padding-left: 40px !important;
  }
  .csn-theme .p-md-5 {
    padding: 50px !important;
  }
  .csn-theme .pt-md-5,
  .csn-theme .py-md-5 {
    padding-top: 50px !important;
  }
  .csn-theme .pr-md-5,
  .csn-theme .px-md-5 {
    padding-right: 50px !important;
  }
  .csn-theme .pb-md-5,
  .csn-theme .py-md-5 {
    padding-bottom: 50px !important;
  }
  .csn-theme .pl-md-5,
  .csn-theme .px-md-5 {
    padding-left: 50px !important;
  }
  .csn-theme .m-md-auto {
    margin: auto !important;
  }
  .csn-theme .mt-md-auto,
  .csn-theme .my-md-auto {
    margin-top: auto !important;
  }
  .csn-theme .mr-md-auto,
  .csn-theme .mx-md-auto {
    margin-right: auto !important;
  }
  .csn-theme .mb-md-auto,
  .csn-theme .my-md-auto {
    margin-bottom: auto !important;
  }
  .csn-theme .ml-md-auto,
  .csn-theme .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .csn-theme .m-lg-0 {
    margin: 0 !important;
  }
  .csn-theme .mt-lg-0,
  .csn-theme .my-lg-0 {
    margin-top: 0 !important;
  }
  .csn-theme .mr-lg-0,
  .csn-theme .mx-lg-0 {
    margin-right: 0 !important;
  }
  .csn-theme .mb-lg-0,
  .csn-theme .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .csn-theme .ml-lg-0,
  .csn-theme .mx-lg-0 {
    margin-left: 0 !important;
  }
  .csn-theme .m-lg-1 {
    margin: 10px !important;
  }
  .csn-theme .mt-lg-1,
  .csn-theme .my-lg-1 {
    margin-top: 10px !important;
  }
  .csn-theme .mr-lg-1,
  .csn-theme .mx-lg-1 {
    margin-right: 10px !important;
  }
  .csn-theme .mb-lg-1,
  .csn-theme .my-lg-1 {
    margin-bottom: 10px !important;
  }
  .csn-theme .ml-lg-1,
  .csn-theme .mx-lg-1 {
    margin-left: 10px !important;
  }
  .csn-theme .m-lg-2 {
    margin: 20px !important;
  }
  .csn-theme .mt-lg-2,
  .csn-theme .my-lg-2 {
    margin-top: 20px !important;
  }
  .csn-theme .mr-lg-2,
  .csn-theme .mx-lg-2 {
    margin-right: 20px !important;
  }
  .csn-theme .mb-lg-2,
  .csn-theme .my-lg-2 {
    margin-bottom: 20px !important;
  }
  .csn-theme .ml-lg-2,
  .csn-theme .mx-lg-2 {
    margin-left: 20px !important;
  }
  .csn-theme .m-lg-3 {
    margin: 30px !important;
  }
  .csn-theme .mt-lg-3,
  .csn-theme .my-lg-3 {
    margin-top: 30px !important;
  }
  .csn-theme .mr-lg-3,
  .csn-theme .mx-lg-3 {
    margin-right: 30px !important;
  }
  .csn-theme .mb-lg-3,
  .csn-theme .my-lg-3 {
    margin-bottom: 30px !important;
  }
  .csn-theme .ml-lg-3,
  .csn-theme .mx-lg-3 {
    margin-left: 30px !important;
  }
  .csn-theme .m-lg-4 {
    margin: 40px !important;
  }
  .csn-theme .mt-lg-4,
  .csn-theme .my-lg-4 {
    margin-top: 40px !important;
  }
  .csn-theme .mr-lg-4,
  .csn-theme .mx-lg-4 {
    margin-right: 40px !important;
  }
  .csn-theme .mb-lg-4,
  .csn-theme .my-lg-4 {
    margin-bottom: 40px !important;
  }
  .csn-theme .ml-lg-4,
  .csn-theme .mx-lg-4 {
    margin-left: 40px !important;
  }
  .csn-theme .m-lg-5 {
    margin: 50px !important;
  }
  .csn-theme .mt-lg-5,
  .csn-theme .my-lg-5 {
    margin-top: 50px !important;
  }
  .csn-theme .mr-lg-5,
  .csn-theme .mx-lg-5 {
    margin-right: 50px !important;
  }
  .csn-theme .mb-lg-5,
  .csn-theme .my-lg-5 {
    margin-bottom: 50px !important;
  }
  .csn-theme .ml-lg-5,
  .csn-theme .mx-lg-5 {
    margin-left: 50px !important;
  }
  .csn-theme .p-lg-0 {
    padding: 0 !important;
  }
  .csn-theme .pt-lg-0,
  .csn-theme .py-lg-0 {
    padding-top: 0 !important;
  }
  .csn-theme .pr-lg-0,
  .csn-theme .px-lg-0 {
    padding-right: 0 !important;
  }
  .csn-theme .pb-lg-0,
  .csn-theme .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .csn-theme .pl-lg-0,
  .csn-theme .px-lg-0 {
    padding-left: 0 !important;
  }
  .csn-theme .p-lg-1 {
    padding: 10px !important;
  }
  .csn-theme .pt-lg-1,
  .csn-theme .py-lg-1 {
    padding-top: 10px !important;
  }
  .csn-theme .pr-lg-1,
  .csn-theme .px-lg-1 {
    padding-right: 10px !important;
  }
  .csn-theme .pb-lg-1,
  .csn-theme .py-lg-1 {
    padding-bottom: 10px !important;
  }
  .csn-theme .pl-lg-1,
  .csn-theme .px-lg-1 {
    padding-left: 10px !important;
  }
  .csn-theme .p-lg-2 {
    padding: 20px !important;
  }
  .csn-theme .pt-lg-2,
  .csn-theme .py-lg-2 {
    padding-top: 20px !important;
  }
  .csn-theme .pr-lg-2,
  .csn-theme .px-lg-2 {
    padding-right: 20px !important;
  }
  .csn-theme .pb-lg-2,
  .csn-theme .py-lg-2 {
    padding-bottom: 20px !important;
  }
  .csn-theme .pl-lg-2,
  .csn-theme .px-lg-2 {
    padding-left: 20px !important;
  }
  .csn-theme .p-lg-3 {
    padding: 30px !important;
  }
  .csn-theme .pt-lg-3,
  .csn-theme .py-lg-3 {
    padding-top: 30px !important;
  }
  .csn-theme .pr-lg-3,
  .csn-theme .px-lg-3 {
    padding-right: 30px !important;
  }
  .csn-theme .pb-lg-3,
  .csn-theme .py-lg-3 {
    padding-bottom: 30px !important;
  }
  .csn-theme .pl-lg-3,
  .csn-theme .px-lg-3 {
    padding-left: 30px !important;
  }
  .csn-theme .p-lg-4 {
    padding: 40px !important;
  }
  .csn-theme .pt-lg-4,
  .csn-theme .py-lg-4 {
    padding-top: 40px !important;
  }
  .csn-theme .pr-lg-4,
  .csn-theme .px-lg-4 {
    padding-right: 40px !important;
  }
  .csn-theme .pb-lg-4,
  .csn-theme .py-lg-4 {
    padding-bottom: 40px !important;
  }
  .csn-theme .pl-lg-4,
  .csn-theme .px-lg-4 {
    padding-left: 40px !important;
  }
  .csn-theme .p-lg-5 {
    padding: 50px !important;
  }
  .csn-theme .pt-lg-5,
  .csn-theme .py-lg-5 {
    padding-top: 50px !important;
  }
  .csn-theme .pr-lg-5,
  .csn-theme .px-lg-5 {
    padding-right: 50px !important;
  }
  .csn-theme .pb-lg-5,
  .csn-theme .py-lg-5 {
    padding-bottom: 50px !important;
  }
  .csn-theme .pl-lg-5,
  .csn-theme .px-lg-5 {
    padding-left: 50px !important;
  }
  .csn-theme .m-lg-auto {
    margin: auto !important;
  }
  .csn-theme .mt-lg-auto,
  .csn-theme .my-lg-auto {
    margin-top: auto !important;
  }
  .csn-theme .mr-lg-auto,
  .csn-theme .mx-lg-auto {
    margin-right: auto !important;
  }
  .csn-theme .mb-lg-auto,
  .csn-theme .my-lg-auto {
    margin-bottom: auto !important;
  }
  .csn-theme .ml-lg-auto,
  .csn-theme .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .csn-theme .m-xl-0 {
    margin: 0 !important;
  }
  .csn-theme .mt-xl-0,
  .csn-theme .my-xl-0 {
    margin-top: 0 !important;
  }
  .csn-theme .mr-xl-0,
  .csn-theme .mx-xl-0 {
    margin-right: 0 !important;
  }
  .csn-theme .mb-xl-0,
  .csn-theme .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .csn-theme .ml-xl-0,
  .csn-theme .mx-xl-0 {
    margin-left: 0 !important;
  }
  .csn-theme .m-xl-1 {
    margin: 10px !important;
  }
  .csn-theme .mt-xl-1,
  .csn-theme .my-xl-1 {
    margin-top: 10px !important;
  }
  .csn-theme .mr-xl-1,
  .csn-theme .mx-xl-1 {
    margin-right: 10px !important;
  }
  .csn-theme .mb-xl-1,
  .csn-theme .my-xl-1 {
    margin-bottom: 10px !important;
  }
  .csn-theme .ml-xl-1,
  .csn-theme .mx-xl-1 {
    margin-left: 10px !important;
  }
  .csn-theme .m-xl-2 {
    margin: 20px !important;
  }
  .csn-theme .mt-xl-2,
  .csn-theme .my-xl-2 {
    margin-top: 20px !important;
  }
  .csn-theme .mr-xl-2,
  .csn-theme .mx-xl-2 {
    margin-right: 20px !important;
  }
  .csn-theme .mb-xl-2,
  .csn-theme .my-xl-2 {
    margin-bottom: 20px !important;
  }
  .csn-theme .ml-xl-2,
  .csn-theme .mx-xl-2 {
    margin-left: 20px !important;
  }
  .csn-theme .m-xl-3 {
    margin: 30px !important;
  }
  .csn-theme .mt-xl-3,
  .csn-theme .my-xl-3 {
    margin-top: 30px !important;
  }
  .csn-theme .mr-xl-3,
  .csn-theme .mx-xl-3 {
    margin-right: 30px !important;
  }
  .csn-theme .mb-xl-3,
  .csn-theme .my-xl-3 {
    margin-bottom: 30px !important;
  }
  .csn-theme .ml-xl-3,
  .csn-theme .mx-xl-3 {
    margin-left: 30px !important;
  }
  .csn-theme .m-xl-4 {
    margin: 40px !important;
  }
  .csn-theme .mt-xl-4,
  .csn-theme .my-xl-4 {
    margin-top: 40px !important;
  }
  .csn-theme .mr-xl-4,
  .csn-theme .mx-xl-4 {
    margin-right: 40px !important;
  }
  .csn-theme .mb-xl-4,
  .csn-theme .my-xl-4 {
    margin-bottom: 40px !important;
  }
  .csn-theme .ml-xl-4,
  .csn-theme .mx-xl-4 {
    margin-left: 40px !important;
  }
  .csn-theme .m-xl-5 {
    margin: 50px !important;
  }
  .csn-theme .mt-xl-5,
  .csn-theme .my-xl-5 {
    margin-top: 50px !important;
  }
  .csn-theme .mr-xl-5,
  .csn-theme .mx-xl-5 {
    margin-right: 50px !important;
  }
  .csn-theme .mb-xl-5,
  .csn-theme .my-xl-5 {
    margin-bottom: 50px !important;
  }
  .csn-theme .ml-xl-5,
  .csn-theme .mx-xl-5 {
    margin-left: 50px !important;
  }
  .csn-theme .p-xl-0 {
    padding: 0 !important;
  }
  .csn-theme .pt-xl-0,
  .csn-theme .py-xl-0 {
    padding-top: 0 !important;
  }
  .csn-theme .pr-xl-0,
  .csn-theme .px-xl-0 {
    padding-right: 0 !important;
  }
  .csn-theme .pb-xl-0,
  .csn-theme .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .csn-theme .pl-xl-0,
  .csn-theme .px-xl-0 {
    padding-left: 0 !important;
  }
  .csn-theme .p-xl-1 {
    padding: 10px !important;
  }
  .csn-theme .pt-xl-1,
  .csn-theme .py-xl-1 {
    padding-top: 10px !important;
  }
  .csn-theme .pr-xl-1,
  .csn-theme .px-xl-1 {
    padding-right: 10px !important;
  }
  .csn-theme .pb-xl-1,
  .csn-theme .py-xl-1 {
    padding-bottom: 10px !important;
  }
  .csn-theme .pl-xl-1,
  .csn-theme .px-xl-1 {
    padding-left: 10px !important;
  }
  .csn-theme .p-xl-2 {
    padding: 20px !important;
  }
  .csn-theme .pt-xl-2,
  .csn-theme .py-xl-2 {
    padding-top: 20px !important;
  }
  .csn-theme .pr-xl-2,
  .csn-theme .px-xl-2 {
    padding-right: 20px !important;
  }
  .csn-theme .pb-xl-2,
  .csn-theme .py-xl-2 {
    padding-bottom: 20px !important;
  }
  .csn-theme .pl-xl-2,
  .csn-theme .px-xl-2 {
    padding-left: 20px !important;
  }
  .csn-theme .p-xl-3 {
    padding: 30px !important;
  }
  .csn-theme .pt-xl-3,
  .csn-theme .py-xl-3 {
    padding-top: 30px !important;
  }
  .csn-theme .pr-xl-3,
  .csn-theme .px-xl-3 {
    padding-right: 30px !important;
  }
  .csn-theme .pb-xl-3,
  .csn-theme .py-xl-3 {
    padding-bottom: 30px !important;
  }
  .csn-theme .pl-xl-3,
  .csn-theme .px-xl-3 {
    padding-left: 30px !important;
  }
  .csn-theme .p-xl-4 {
    padding: 40px !important;
  }
  .csn-theme .pt-xl-4,
  .csn-theme .py-xl-4 {
    padding-top: 40px !important;
  }
  .csn-theme .pr-xl-4,
  .csn-theme .px-xl-4 {
    padding-right: 40px !important;
  }
  .csn-theme .pb-xl-4,
  .csn-theme .py-xl-4 {
    padding-bottom: 40px !important;
  }
  .csn-theme .pl-xl-4,
  .csn-theme .px-xl-4 {
    padding-left: 40px !important;
  }
  .csn-theme .p-xl-5 {
    padding: 50px !important;
  }
  .csn-theme .pt-xl-5,
  .csn-theme .py-xl-5 {
    padding-top: 50px !important;
  }
  .csn-theme .pr-xl-5,
  .csn-theme .px-xl-5 {
    padding-right: 50px !important;
  }
  .csn-theme .pb-xl-5,
  .csn-theme .py-xl-5 {
    padding-bottom: 50px !important;
  }
  .csn-theme .pl-xl-5,
  .csn-theme .px-xl-5 {
    padding-left: 50px !important;
  }
  .csn-theme .m-xl-auto {
    margin: auto !important;
  }
  .csn-theme .mt-xl-auto,
  .csn-theme .my-xl-auto {
    margin-top: auto !important;
  }
  .csn-theme .mr-xl-auto,
  .csn-theme .mx-xl-auto {
    margin-right: auto !important;
  }
  .csn-theme .mb-xl-auto,
  .csn-theme .my-xl-auto {
    margin-bottom: auto !important;
  }
  .csn-theme .ml-xl-auto,
  .csn-theme .mx-xl-auto {
    margin-left: auto !important;
  }
}

.csn-theme .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.csn-theme .text-justify {
  text-align: justify !important;
}

.csn-theme .text-nowrap {
  white-space: nowrap !important;
}

.csn-theme .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.csn-theme .text-left {
  text-align: left !important;
}

.csn-theme .text-right {
  text-align: right !important;
}

.csn-theme .text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .csn-theme .text-sm-left {
    text-align: left !important;
  }
  .csn-theme .text-sm-right {
    text-align: right !important;
  }
  .csn-theme .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .csn-theme .text-md-left {
    text-align: left !important;
  }
  .csn-theme .text-md-right {
    text-align: right !important;
  }
  .csn-theme .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .csn-theme .text-lg-left {
    text-align: left !important;
  }
  .csn-theme .text-lg-right {
    text-align: right !important;
  }
  .csn-theme .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .csn-theme .text-xl-left {
    text-align: left !important;
  }
  .csn-theme .text-xl-right {
    text-align: right !important;
  }
  .csn-theme .text-xl-center {
    text-align: center !important;
  }
}

.csn-theme .text-lowercase {
  text-transform: lowercase !important;
}

.csn-theme .text-uppercase {
  text-transform: uppercase !important;
}

.csn-theme .text-capitalize {
  text-transform: capitalize !important;
}

.csn-theme .font-weight-light {
  font-weight: 300 !important;
}

.csn-theme .font-weight-normal {
  font-weight: 400 !important;
}

.csn-theme .font-weight-bold {
  font-weight: 600 !important;
}

.csn-theme .font-italic {
  font-style: italic !important;
}

.csn-theme .text-white {
  color: #fff !important;
}

.csn-theme .text-primary {
  color: #4da32f !important;
}

.csn-theme a.text-primary:hover, .csn-theme a.text-primary:focus {
  color: #3a7b24 !important;
}

.csn-theme .text-primary-dark {
  color: #266e44 !important;
}

.csn-theme a.text-primary-dark:hover, .csn-theme a.text-primary-dark:focus {
  color: #19482d !important;
}

.csn-theme .text-secondary {
  color: #FF7B00 !important;
}

.csn-theme a.text-secondary:hover, .csn-theme a.text-secondary:focus {
  color: #cc6200 !important;
}

.csn-theme .text-success {
  color: #41DBA0 !important;
}

.csn-theme a.text-success:hover, .csn-theme a.text-success:focus {
  color: #25c487 !important;
}

.csn-theme .text-info {
  color: #007CC2 !important;
}

.csn-theme a.text-info:hover, .csn-theme a.text-info:focus {
  color: #005b8f !important;
}

.csn-theme .text-warning {
  color: #ED1C24 !important;
}

.csn-theme a.text-warning:hover, .csn-theme a.text-warning:focus {
  color: #c61017 !important;
}

.csn-theme .text-danger {
  color: #ED1C24 !important;
}

.csn-theme a.text-danger:hover, .csn-theme a.text-danger:focus {
  color: #c61017 !important;
}

.csn-theme .text-light {
  color: #f6f6f6 !important;
}

.csn-theme a.text-light:hover, .csn-theme a.text-light:focus {
  color: #dddddd !important;
}

.csn-theme .text-dark {
  color: #4a4a4a !important;
}

.csn-theme a.text-dark:hover, .csn-theme a.text-dark:focus {
  color: #313131 !important;
}

.csn-theme .text-body {
  color: #4a4a4a !important;
}

.csn-theme .text-muted {
  color: #9b9b9b !important;
}

.csn-theme .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.csn-theme .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.csn-theme .visible {
  visibility: visible !important;
}

.csn-theme .invisible {
  visibility: hidden !important;
}

.csn-theme .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6e6e6e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .form-control {
    transition: none;
  }
}

.csn-theme .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.csn-theme .form-control:focus {
  color: #6e6e6e;
  background-color: #fff;
  border-color: #92d879;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .form-control::-webkit-input-placeholder {
  color: #9b9b9b;
  opacity: 1;
}

.csn-theme .form-control::-moz-placeholder {
  color: #9b9b9b;
  opacity: 1;
}

.csn-theme .form-control:-ms-input-placeholder {
  color: #9b9b9b;
  opacity: 1;
}

.csn-theme .form-control::-ms-input-placeholder {
  color: #9b9b9b;
  opacity: 1;
}

.csn-theme .form-control::placeholder {
  color: #9b9b9b;
  opacity: 1;
}

.csn-theme .form-control:disabled, .csn-theme .form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}

.csn-theme select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 2px);
}

.csn-theme select.form-control:focus::-ms-value {
  color: #6e6e6e;
  background-color: #fff;
}

.csn-theme .form-control-file,
.csn-theme .form-control-range {
  display: block;
  width: 100%;
}

.csn-theme .col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.csn-theme .col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.csn-theme .col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.csn-theme .form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.csn-theme .form-control-plaintext.form-control-sm, .csn-theme .input-group-sm > .form-control-plaintext.form-control,
.csn-theme .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.csn-theme .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.csn-theme .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.csn-theme .input-group-sm > .input-group-append > .form-control-plaintext.btn, .csn-theme .form-control-plaintext.form-control-lg, .csn-theme .input-group-lg > .form-control-plaintext.form-control,
.csn-theme .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.csn-theme .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.csn-theme .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.csn-theme .input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.csn-theme .form-control-sm, .csn-theme .input-group-sm > .form-control,
.csn-theme .input-group-sm > .input-group-prepend > .input-group-text,
.csn-theme .input-group-sm > .input-group-append > .input-group-text,
.csn-theme .input-group-sm > .input-group-prepend > .btn,
.csn-theme .input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.csn-theme select.form-control-sm:not([size]):not([multiple]), .csn-theme .input-group-sm > select.form-control:not([size]):not([multiple]),
.csn-theme .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.csn-theme .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.csn-theme .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.csn-theme .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.648438rem + 2px);
}

.csn-theme .form-control-lg, .csn-theme .input-group-lg > .form-control,
.csn-theme .input-group-lg > .input-group-prepend > .input-group-text,
.csn-theme .input-group-lg > .input-group-append > .input-group-text,
.csn-theme .input-group-lg > .input-group-prepend > .btn,
.csn-theme .input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.csn-theme select.form-control-lg:not([size]):not([multiple]), .csn-theme .input-group-lg > select.form-control:not([size]):not([multiple]),
.csn-theme .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.csn-theme .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.csn-theme .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.csn-theme .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.640625rem + 2px);
}

.csn-theme .form-group {
  margin-bottom: 1rem;
}

.csn-theme .form-text {
  display: block;
  margin-top: 0.25rem;
}

.csn-theme .form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.csn-theme .form-row > .col,
.csn-theme .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.csn-theme .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.csn-theme .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.csn-theme .form-check-input:disabled ~ .form-check-label {
  color: #9b9b9b;
}

.csn-theme .form-check-label {
  margin-bottom: 0;
}

.csn-theme .form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.csn-theme .form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.csn-theme .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #41DBA0;
}

.csn-theme .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(65, 219, 160, 0.8);
  border-radius: .2rem;
}

.was-validated .csn-theme .form-control:valid, .csn-theme .form-control.is-valid, .was-validated
.csn-theme .custom-select:valid,
.csn-theme .custom-select.is-valid {
  border-color: #41DBA0;
}

.was-validated .csn-theme .form-control:valid:focus, .csn-theme .form-control.is-valid:focus, .was-validated
.csn-theme .custom-select:valid:focus,
.csn-theme .custom-select.is-valid:focus {
  border-color: #41DBA0;
  box-shadow: 0 0 0 0.2rem rgba(65, 219, 160, 0.25);
}

.was-validated .csn-theme .form-control:valid ~ .valid-feedback,
.was-validated .csn-theme .form-control:valid ~ .valid-tooltip, .csn-theme .form-control.is-valid ~ .valid-feedback,
.csn-theme .form-control.is-valid ~ .valid-tooltip, .was-validated
.csn-theme .custom-select:valid ~ .valid-feedback,
.was-validated
.csn-theme .custom-select:valid ~ .valid-tooltip,
.csn-theme .custom-select.is-valid ~ .valid-feedback,
.csn-theme .custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .csn-theme .form-check-input:valid ~ .form-check-label, .csn-theme .form-check-input.is-valid ~ .form-check-label {
  color: #41DBA0;
}

.was-validated .csn-theme .form-check-input:valid ~ .valid-feedback,
.was-validated .csn-theme .form-check-input:valid ~ .valid-tooltip, .csn-theme .form-check-input.is-valid ~ .valid-feedback,
.csn-theme .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-control-input:valid ~ .custom-control-label, .csn-theme .custom-control-input.is-valid ~ .custom-control-label {
  color: #41DBA0;
}

.was-validated .csn-theme .custom-control-input:valid ~ .custom-control-label::before, .csn-theme .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #acefd6;
}

.was-validated .csn-theme .custom-control-input:valid ~ .valid-feedback,
.was-validated .csn-theme .custom-control-input:valid ~ .valid-tooltip, .csn-theme .custom-control-input.is-valid ~ .valid-feedback,
.csn-theme .custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-control-input:valid:checked ~ .custom-control-label::before, .csn-theme .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6ce3b5;
}

.was-validated .csn-theme .custom-control-input:valid:focus ~ .custom-control-label::before, .csn-theme .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(65, 219, 160, 0.25);
}

.was-validated .csn-theme .custom-file-input:valid ~ .custom-file-label, .csn-theme .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #41DBA0;
}

.was-validated .csn-theme .custom-file-input:valid ~ .custom-file-label::before, .csn-theme .custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .csn-theme .custom-file-input:valid ~ .valid-feedback,
.was-validated .csn-theme .custom-file-input:valid ~ .valid-tooltip, .csn-theme .custom-file-input.is-valid ~ .valid-feedback,
.csn-theme .custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-file-input:valid:focus ~ .custom-file-label, .csn-theme .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(65, 219, 160, 0.25);
}

.csn-theme .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ED1C24;
}

.csn-theme .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(237, 28, 36, 0.8);
  border-radius: .2rem;
}

.was-validated .csn-theme .form-control:invalid, .csn-theme .form-control.is-invalid, .was-validated
.csn-theme .custom-select:invalid,
.csn-theme .custom-select.is-invalid {
  border-color: #ED1C24;
}

.was-validated .csn-theme .form-control:invalid:focus, .csn-theme .form-control.is-invalid:focus, .was-validated
.csn-theme .custom-select:invalid:focus,
.csn-theme .custom-select.is-invalid:focus {
  border-color: #ED1C24;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25);
}

.was-validated .csn-theme .form-control:invalid ~ .invalid-feedback,
.was-validated .csn-theme .form-control:invalid ~ .invalid-tooltip, .csn-theme .form-control.is-invalid ~ .invalid-feedback,
.csn-theme .form-control.is-invalid ~ .invalid-tooltip, .was-validated
.csn-theme .custom-select:invalid ~ .invalid-feedback,
.was-validated
.csn-theme .custom-select:invalid ~ .invalid-tooltip,
.csn-theme .custom-select.is-invalid ~ .invalid-feedback,
.csn-theme .custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .csn-theme .form-check-input:invalid ~ .form-check-label, .csn-theme .form-check-input.is-invalid ~ .form-check-label {
  color: #ED1C24;
}

.was-validated .csn-theme .form-check-input:invalid ~ .invalid-feedback,
.was-validated .csn-theme .form-check-input:invalid ~ .invalid-tooltip, .csn-theme .form-check-input.is-invalid ~ .invalid-feedback,
.csn-theme .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-control-input:invalid ~ .custom-control-label, .csn-theme .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ED1C24;
}

.was-validated .csn-theme .custom-control-input:invalid ~ .custom-control-label::before, .csn-theme .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #f69296;
}

.was-validated .csn-theme .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .csn-theme .custom-control-input:invalid ~ .invalid-tooltip, .csn-theme .custom-control-input.is-invalid ~ .invalid-feedback,
.csn-theme .custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-control-input:invalid:checked ~ .custom-control-label::before, .csn-theme .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f14b52;
}

.was-validated .csn-theme .custom-control-input:invalid:focus ~ .custom-control-label::before, .csn-theme .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(237, 28, 36, 0.25);
}

.was-validated .csn-theme .custom-file-input:invalid ~ .custom-file-label, .csn-theme .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ED1C24;
}

.was-validated .csn-theme .custom-file-input:invalid ~ .custom-file-label::before, .csn-theme .custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .csn-theme .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .csn-theme .custom-file-input:invalid ~ .invalid-tooltip, .csn-theme .custom-file-input.is-invalid ~ .invalid-feedback,
.csn-theme .custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .csn-theme .custom-file-input:invalid:focus ~ .custom-file-label, .csn-theme .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25);
}

.csn-theme .form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.csn-theme .form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .csn-theme .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .csn-theme .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .csn-theme .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .csn-theme .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .csn-theme .form-inline .input-group,
  .csn-theme .form-inline .custom-select {
    width: auto;
  }
  .csn-theme .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .csn-theme .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .csn-theme .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .csn-theme .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.csn-theme .dropup,
.csn-theme .dropright,
.csn-theme .dropdown,
.csn-theme .dropleft {
  position: relative;
}

.csn-theme .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.csn-theme .dropdown-toggle:empty::after {
  margin-left: 0;
}

.csn-theme .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #4a4a4a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.csn-theme .dropdown-menu-right {
  right: 0;
  left: auto;
}

.csn-theme .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.csn-theme .dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.csn-theme .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.csn-theme .dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.csn-theme .dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.csn-theme .dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.csn-theme .dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.csn-theme .dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.csn-theme .dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.csn-theme .dropleft .dropdown-toggle::after {
  display: none;
}

.csn-theme .dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.csn-theme .dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.csn-theme .dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.csn-theme .dropdown-menu[x-placement^="top"], .csn-theme .dropdown-menu[x-placement^="right"], .csn-theme .dropdown-menu[x-placement^="bottom"], .csn-theme .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.csn-theme .dropdown-divider {
  height: 0;
  margin: 5px 0;
  overflow: hidden;
  border-top: 1px solid #f2f2f2;
}

.csn-theme .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.csn-theme .dropdown-item:hover, .csn-theme .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f6f6f6;
}

.csn-theme .dropdown-item.active, .csn-theme .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #4da32f;
}

.csn-theme .dropdown-item.disabled, .csn-theme .dropdown-item:disabled {
  color: #9b9b9b;
  background-color: transparent;
}

.csn-theme .dropdown-menu.show {
  display: block;
}

.csn-theme .dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #9b9b9b;
  white-space: nowrap;
}

.csn-theme .dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.csn-theme .btn-group,
.csn-theme .btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.csn-theme .btn-group > .btn,
.csn-theme .btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.csn-theme .btn-group > .btn:hover,
.csn-theme .btn-group-vertical > .btn:hover {
  z-index: 1;
}

.csn-theme .btn-group > .btn:focus, .csn-theme .btn-group > .btn:active, .csn-theme .btn-group > .btn.active,
.csn-theme .btn-group-vertical > .btn:focus,
.csn-theme .btn-group-vertical > .btn:active,
.csn-theme .btn-group-vertical > .btn.active {
  z-index: 1;
}

.csn-theme .btn-group .btn + .btn,
.csn-theme .btn-group .btn + .btn-group,
.csn-theme .btn-group .btn-group + .btn,
.csn-theme .btn-group .btn-group + .btn-group,
.csn-theme .btn-group-vertical .btn + .btn,
.csn-theme .btn-group-vertical .btn + .btn-group,
.csn-theme .btn-group-vertical .btn-group + .btn,
.csn-theme .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.csn-theme .btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.csn-theme .btn-toolbar .input-group {
  width: auto;
}

.csn-theme .btn-group > .btn:first-child {
  margin-left: 0;
}

.csn-theme .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.csn-theme .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.csn-theme .btn-group > .btn:not(:first-child),
.csn-theme .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.csn-theme .dropdown-toggle-split::after,
.dropup .csn-theme .dropdown-toggle-split::after,
.dropright .csn-theme .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .csn-theme .dropdown-toggle-split::before {
  margin-right: 0;
}

.csn-theme .btn-sm + .dropdown-toggle-split, .csn-theme .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.csn-theme .btn-lg + .dropdown-toggle-split, .csn-theme .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.csn-theme .btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.csn-theme .btn-group-vertical .btn,
.csn-theme .btn-group-vertical .btn-group {
  width: 100%;
}

.csn-theme .btn-group-vertical > .btn + .btn,
.csn-theme .btn-group-vertical > .btn + .btn-group,
.csn-theme .btn-group-vertical > .btn-group + .btn,
.csn-theme .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.csn-theme .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.csn-theme .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .btn-group-vertical > .btn:not(:first-child),
.csn-theme .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.csn-theme .btn-group-toggle > .btn,
.csn-theme .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.csn-theme .btn-group-toggle > .btn input[type="radio"],
.csn-theme .btn-group-toggle > .btn input[type="checkbox"],
.csn-theme .btn-group-toggle > .btn-group > .btn input[type="radio"],
.csn-theme .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.csn-theme .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.csn-theme .input-group > .form-control,
.csn-theme .input-group > .custom-select,
.csn-theme .input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.csn-theme .input-group > .form-control:focus,
.csn-theme .input-group > .custom-select:focus,
.csn-theme .input-group > .custom-file:focus {
  z-index: 3;
}

.csn-theme .input-group > .form-control + .form-control,
.csn-theme .input-group > .form-control + .custom-select,
.csn-theme .input-group > .form-control + .custom-file,
.csn-theme .input-group > .custom-select + .form-control,
.csn-theme .input-group > .custom-select + .custom-select,
.csn-theme .input-group > .custom-select + .custom-file,
.csn-theme .input-group > .custom-file + .form-control,
.csn-theme .input-group > .custom-file + .custom-select,
.csn-theme .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.csn-theme .input-group > .form-control:not(:last-child),
.csn-theme .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.csn-theme .input-group > .form-control:not(:first-child),
.csn-theme .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.csn-theme .input-group > .custom-file:not(:last-child) .custom-file-label,
.csn-theme .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.csn-theme .input-group > .custom-file:not(:first-child) .custom-file-label,
.csn-theme .input-group > .custom-file:not(:first-child) .custom-file-label::after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .input-group-prepend,
.csn-theme .input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.csn-theme .input-group-prepend .btn,
.csn-theme .input-group-append .btn {
  position: relative;
  z-index: 2;
}

.csn-theme .input-group-prepend .btn + .btn,
.csn-theme .input-group-prepend .btn + .input-group-text,
.csn-theme .input-group-prepend .input-group-text + .input-group-text,
.csn-theme .input-group-prepend .input-group-text + .btn,
.csn-theme .input-group-append .btn + .btn,
.csn-theme .input-group-append .btn + .input-group-text,
.csn-theme .input-group-append .input-group-text + .input-group-text,
.csn-theme .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.csn-theme .input-group-prepend {
  margin-right: -1px;
}

.csn-theme .input-group-append {
  margin-left: -1px;
}

.csn-theme .input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e6e6e;
  text-align: center;
  white-space: nowrap;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.csn-theme .input-group-text input[type="radio"],
.csn-theme .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.csn-theme .input-group > .input-group-prepend > .btn,
.csn-theme .input-group > .input-group-prepend > .input-group-text,
.csn-theme .input-group > .input-group-append:not(:last-child) > .btn,
.csn-theme .input-group > .input-group-append:not(:last-child) > .input-group-text,
.csn-theme .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.csn-theme .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.csn-theme .input-group > .input-group-append > .btn,
.csn-theme .input-group > .input-group-append > .input-group-text,
.csn-theme .input-group > .input-group-prepend:not(:first-child) > .btn,
.csn-theme .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.csn-theme .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.csn-theme .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.csn-theme .custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.csn-theme .custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.csn-theme .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #4da32f;
}

.csn-theme .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b2e4a1;
}

.csn-theme .custom-control-input:disabled ~ .custom-control-label {
  color: #9b9b9b;
}

.csn-theme .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f2f2f2;
}

.csn-theme .custom-control-label {
  margin-bottom: 0;
}

.csn-theme .custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #e9e9e9;
}

.csn-theme .custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.csn-theme .custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.csn-theme .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4da32f;
}

.csn-theme .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.csn-theme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #4da32f;
}

.csn-theme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.csn-theme .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(77, 163, 47, 0.5);
}

.csn-theme .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(77, 163, 47, 0.5);
}

.csn-theme .custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.csn-theme .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4da32f;
}

.csn-theme .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.csn-theme .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(77, 163, 47, 0.5);
}

.csn-theme .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #6e6e6e;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%234a4a4a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.csn-theme .custom-select:focus {
  border-color: #92d879;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(146, 216, 121, 0.5);
}

.csn-theme .custom-select:focus::-ms-value {
  color: #6e6e6e;
  background-color: #fff;
}

.csn-theme .custom-select[multiple], .csn-theme .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.csn-theme .custom-select:disabled {
  color: #9b9b9b;
  background-color: #f2f2f2;
}

.csn-theme .custom-select::-ms-expand {
  opacity: 0;
}

.csn-theme .custom-select-sm {
  height: calc(1.648438rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.csn-theme .custom-select-lg {
  height: calc(2.640625rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.csn-theme .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin-bottom: 0;
}

.csn-theme .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.0625rem + 2px);
  margin: 0;
  opacity: 0;
}

.csn-theme .custom-file-input:focus ~ .custom-file-label {
  border-color: #92d879;
  box-shadow: 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .custom-file-input:focus ~ .custom-file-label::after {
  border-color: #92d879;
}

.csn-theme .custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.csn-theme .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #6e6e6e;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.csn-theme .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.0625rem + 2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #6e6e6e;
  content: "Browse";
  background-color: #f2f2f2;
  border-left: 1px solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}

.csn-theme .custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.csn-theme .custom-range:focus {
  outline: none;
}

.csn-theme .custom-range::-moz-focus-outer {
  border: 0;
}

.csn-theme .custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4da32f;
  border: 0;
  border-radius: 1rem;
  -webkit-appearance: none;
  appearance: none;
}

.csn-theme .custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .custom-range::-webkit-slider-thumb:active {
  background-color: #b2e4a1;
}

.csn-theme .custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e9e9e9;
  border-color: transparent;
  border-radius: 1rem;
}

.csn-theme .custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4da32f;
  border: 0;
  border-radius: 1rem;
  -moz-appearance: none;
  appearance: none;
}

.csn-theme .custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .custom-range::-moz-range-thumb:active {
  background-color: #b2e4a1;
}

.csn-theme .custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e9e9e9;
  border-color: transparent;
  border-radius: 1rem;
}

.csn-theme .custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4da32f;
  border: 0;
  border-radius: 1rem;
  appearance: none;
}

.csn-theme .custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .custom-range::-ms-thumb:active {
  background-color: #b2e4a1;
}

.csn-theme .custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.csn-theme .custom-range::-ms-fill-lower {
  background-color: #e9e9e9;
  border-radius: 1rem;
}

.csn-theme .custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e9e9e9;
  border-radius: 1rem;
}

.csn-theme .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.csn-theme .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.csn-theme .nav-link:hover, .csn-theme .nav-link:focus {
  text-decoration: none;
}

.csn-theme .nav-link.disabled {
  color: #9b9b9b;
}

.csn-theme .nav-tabs {
  border-bottom: 1px solid #e9e9e9;
}

.csn-theme .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.csn-theme .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.csn-theme .nav-tabs .nav-link:hover, .csn-theme .nav-tabs .nav-link:focus {
  border-color: #f2f2f2 #f2f2f2 #e9e9e9;
}

.csn-theme .nav-tabs .nav-link.disabled {
  color: #9b9b9b;
  background-color: transparent;
  border-color: transparent;
}

.csn-theme .nav-tabs .nav-link.active,
.csn-theme .nav-tabs .nav-item.show .nav-link {
  color: #6e6e6e;
  background-color: #fff;
  border-color: #e9e9e9 #e9e9e9 #fff;
}

.csn-theme .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.csn-theme .nav-pills .nav-link {
  border-radius: 0.25rem;
}

.csn-theme .nav-pills .nav-link.active,
.csn-theme .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4da32f;
}

.csn-theme .nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.csn-theme .nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.csn-theme .tab-content > .tab-pane {
  display: none;
}

.csn-theme .tab-content > .active {
  display: block;
}

.csn-theme .navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 10px;
}

.csn-theme .navbar > .container,
.csn-theme .navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.csn-theme .navbar-brand {
  display: inline-block;
  padding-top: 0.335938rem;
  padding-bottom: 0.335938rem;
  margin-right: 10px;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}

.csn-theme .navbar-brand:hover, .csn-theme .navbar-brand:focus {
  text-decoration: none;
}

.csn-theme .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.csn-theme .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.csn-theme .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.csn-theme .navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.csn-theme .navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.csn-theme .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10rem;
}

.csn-theme .navbar-toggler:hover, .csn-theme .navbar-toggler:focus {
  text-decoration: none;
}

.csn-theme .navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.csn-theme .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .csn-theme .navbar-expand-sm > .container,
  .csn-theme .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .csn-theme .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .csn-theme .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .csn-theme .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .csn-theme .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .csn-theme .navbar-expand-sm > .container,
  .csn-theme .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .csn-theme .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .csn-theme .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .csn-theme .navbar-expand-md > .container,
  .csn-theme .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .csn-theme .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .csn-theme .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .csn-theme .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .csn-theme .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .csn-theme .navbar-expand-md > .container,
  .csn-theme .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .csn-theme .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .csn-theme .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .csn-theme .navbar-expand-lg > .container,
  .csn-theme .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .csn-theme .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .csn-theme .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .csn-theme .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .csn-theme .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .csn-theme .navbar-expand-lg > .container,
  .csn-theme .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .csn-theme .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .csn-theme .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .csn-theme .navbar-expand-xl > .container,
  .csn-theme .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .csn-theme .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .csn-theme .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .csn-theme .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .csn-theme .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .csn-theme .navbar-expand-xl > .container,
  .csn-theme .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .csn-theme .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .csn-theme .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.csn-theme .navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.csn-theme .navbar-expand > .container,
.csn-theme .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.csn-theme .navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.csn-theme .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.csn-theme .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.csn-theme .navbar-expand > .container,
.csn-theme .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.csn-theme .navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.csn-theme .navbar-expand .navbar-toggler {
  display: none;
}

.csn-theme .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.csn-theme .navbar-light .navbar-brand:hover, .csn-theme .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.csn-theme .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.csn-theme .navbar-light .navbar-nav .nav-link:hover, .csn-theme .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.csn-theme .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.csn-theme .navbar-light .navbar-nav .show > .nav-link,
.csn-theme .navbar-light .navbar-nav .active > .nav-link,
.csn-theme .navbar-light .navbar-nav .nav-link.show,
.csn-theme .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.csn-theme .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.csn-theme .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.csn-theme .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.csn-theme .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.csn-theme .navbar-light .navbar-text a:hover, .csn-theme .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.csn-theme .navbar-dark .navbar-brand {
  color: #fff;
}

.csn-theme .navbar-dark .navbar-brand:hover, .csn-theme .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.csn-theme .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.csn-theme .navbar-dark .navbar-nav .nav-link:hover, .csn-theme .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.csn-theme .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.csn-theme .navbar-dark .navbar-nav .show > .nav-link,
.csn-theme .navbar-dark .navbar-nav .active > .nav-link,
.csn-theme .navbar-dark .navbar-nav .nav-link.show,
.csn-theme .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.csn-theme .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.csn-theme .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.csn-theme .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.csn-theme .navbar-dark .navbar-text a {
  color: #fff;
}

.csn-theme .navbar-dark .navbar-text a:hover, .csn-theme .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.csn-theme .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.csn-theme .card > hr {
  margin-right: 0;
  margin-left: 0;
}

.csn-theme .card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.csn-theme .card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.csn-theme .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.csn-theme .card-title {
  margin-bottom: 0.75rem;
}

.csn-theme .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.csn-theme .card-text:last-child {
  margin-bottom: 0;
}

.csn-theme .card-link:hover {
  text-decoration: none;
}

.csn-theme .card-link + .card-link {
  margin-left: 1.25rem;
}

.csn-theme .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.csn-theme .card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.csn-theme .card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.csn-theme .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.csn-theme .card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.csn-theme .card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.csn-theme .card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.csn-theme .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.csn-theme .card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.csn-theme .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.csn-theme .card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.csn-theme .card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.csn-theme .card-deck .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .csn-theme .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .csn-theme .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.csn-theme .card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.csn-theme .card-group > .card {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .csn-theme .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .csn-theme .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .csn-theme .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .csn-theme .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .csn-theme .card-group > .card:first-child .card-img-top,
  .csn-theme .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .csn-theme .card-group > .card:first-child .card-img-bottom,
  .csn-theme .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .csn-theme .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .csn-theme .card-group > .card:last-child .card-img-top,
  .csn-theme .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .csn-theme .card-group > .card:last-child .card-img-bottom,
  .csn-theme .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .csn-theme .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .csn-theme .card-group > .card:only-child .card-img-top,
  .csn-theme .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .csn-theme .card-group > .card:only-child .card-img-bottom,
  .csn-theme .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .csn-theme .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .csn-theme .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .csn-theme .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .csn-theme .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .csn-theme .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.csn-theme .card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .csn-theme .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .csn-theme .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.csn-theme .accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.csn-theme .accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.csn-theme .accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.csn-theme .accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.csn-theme .breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f2f2f2;
  border-radius: 0.25rem;
}

.csn-theme .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.csn-theme .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #9b9b9b;
  content: "/";
}

.csn-theme .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.csn-theme .breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.csn-theme .breadcrumb-item.active {
  color: #9b9b9b;
}

.csn-theme .pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.csn-theme .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #266e44;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.csn-theme .page-link:hover {
  z-index: 2;
  color: #266e44;
  text-decoration: none;
  background-color: #f2f2f2;
  border-color: #e9e9e9;
}

.csn-theme .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 163, 47, 0.25);
}

.csn-theme .page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.csn-theme .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.csn-theme .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.csn-theme .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #4da32f;
  border-color: #4da32f;
}

.csn-theme .page-item.disabled .page-link {
  color: #9b9b9b;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e9e9e9;
}

.csn-theme .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.csn-theme .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.csn-theme .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.csn-theme .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}

.csn-theme .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.csn-theme .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.csn-theme .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .csn-theme .jumbotron {
    padding: 4rem 2rem;
  }
}

.csn-theme .jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.csn-theme .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.csn-theme .alert-heading {
  color: inherit;
}

.csn-theme .alert-link {
  font-weight: 600;
}

.csn-theme .alert-dismissible {
  padding-right: 3.8125rem;
}

.csn-theme .alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.csn-theme .alert-primary {
  color: #285518;
  background-color: #dbedd5;
  border-color: #cde5c5;
}

.csn-theme .alert-primary hr {
  border-top-color: #beddb3;
}

.csn-theme .alert-primary .alert-link {
  color: #1f4112;
}

.csn-theme .alert-primary-dark {
  color: #143923;
  background-color: #d4e2da;
  border-color: #c2d6cb;
}

.csn-theme .alert-primary-dark hr {
  border-top-color: #b3ccbe;
}

.csn-theme .alert-primary-dark .alert-link {
  color: #0d2617;
}

.csn-theme .alert-secondary {
  color: #854000;
  background-color: #ffe5cc;
  border-color: #ffdab8;
}

.csn-theme .alert-secondary hr {
  border-top-color: #ffcd9f;
}

.csn-theme .alert-secondary .alert-link {
  color: #6c3400;
}

.csn-theme .alert-success {
  color: #227253;
  background-color: #d9f8ec;
  border-color: #caf5e4;
}

.csn-theme .alert-success hr {
  border-top-color: #b5f1d9;
}

.csn-theme .alert-success .alert-link {
  color: #1c5e45;
}

.csn-theme .alert-info {
  color: #004065;
  background-color: #cce5f3;
  border-color: #b8daee;
}

.csn-theme .alert-info hr {
  border-top-color: #a3cfe9;
}

.csn-theme .alert-info .alert-link {
  color: #00304c;
}

.csn-theme .alert-warning {
  color: #7b0f13;
  background-color: #fbd2d3;
  border-color: #fabfc2;
}

.csn-theme .alert-warning hr {
  border-top-color: #f8a7ab;
}

.csn-theme .alert-warning .alert-link {
  color: #640c0f;
}

.csn-theme .alert-danger {
  color: #7b0f13;
  background-color: #fbd2d3;
  border-color: #fabfc2;
}

.csn-theme .alert-danger hr {
  border-top-color: #f8a7ab;
}

.csn-theme .alert-danger .alert-link {
  color: #640c0f;
}

.csn-theme .alert-light {
  color: gray;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.csn-theme .alert-light hr {
  border-top-color: #efefef;
}

.csn-theme .alert-light .alert-link {
  color: #737373;
}

.csn-theme .alert-dark {
  color: #262626;
  background-color: #dbdbdb;
  border-color: #cccccc;
}

.csn-theme .alert-dark hr {
  border-top-color: #bfbfbf;
}

.csn-theme .alert-dark .alert-link {
  color: #191919;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.csn-theme .progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #f2f2f2;
  border-radius: 0.25rem;
}

.csn-theme .progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4da32f;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .progress-bar {
    transition: none;
  }
}

.csn-theme .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.csn-theme .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.csn-theme .media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.csn-theme .media-body {
  -ms-flex: 1;
  flex: 1;
}

.csn-theme .list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.csn-theme .list-group-item-action {
  width: 100%;
  color: #6e6e6e;
  text-align: inherit;
}

.csn-theme .list-group-item-action:hover, .csn-theme .list-group-item-action:focus {
  color: #6e6e6e;
  text-decoration: none;
  background-color: #f6f6f6;
}

.csn-theme .list-group-item-action:active {
  color: #4a4a4a;
  background-color: #f2f2f2;
}

.csn-theme .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.csn-theme .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.csn-theme .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.csn-theme .list-group-item:hover, .csn-theme .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.csn-theme .list-group-item.disabled, .csn-theme .list-group-item:disabled {
  color: #9b9b9b;
  background-color: #fff;
}

.csn-theme .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4da32f;
  border-color: #4da32f;
}

.csn-theme .list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.csn-theme .list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.csn-theme .list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.csn-theme .list-group-item-primary {
  color: #285518;
  background-color: #cde5c5;
}

.csn-theme .list-group-item-primary.list-group-item-action:hover, .csn-theme .list-group-item-primary.list-group-item-action:focus {
  color: #285518;
  background-color: #beddb3;
}

.csn-theme .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #285518;
  border-color: #285518;
}

.csn-theme .list-group-item-primary-dark {
  color: #143923;
  background-color: #c2d6cb;
}

.csn-theme .list-group-item-primary-dark.list-group-item-action:hover, .csn-theme .list-group-item-primary-dark.list-group-item-action:focus {
  color: #143923;
  background-color: #b3ccbe;
}

.csn-theme .list-group-item-primary-dark.list-group-item-action.active {
  color: #fff;
  background-color: #143923;
  border-color: #143923;
}

.csn-theme .list-group-item-secondary {
  color: #854000;
  background-color: #ffdab8;
}

.csn-theme .list-group-item-secondary.list-group-item-action:hover, .csn-theme .list-group-item-secondary.list-group-item-action:focus {
  color: #854000;
  background-color: #ffcd9f;
}

.csn-theme .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #854000;
  border-color: #854000;
}

.csn-theme .list-group-item-success {
  color: #227253;
  background-color: #caf5e4;
}

.csn-theme .list-group-item-success.list-group-item-action:hover, .csn-theme .list-group-item-success.list-group-item-action:focus {
  color: #227253;
  background-color: #b5f1d9;
}

.csn-theme .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #227253;
  border-color: #227253;
}

.csn-theme .list-group-item-info {
  color: #004065;
  background-color: #b8daee;
}

.csn-theme .list-group-item-info.list-group-item-action:hover, .csn-theme .list-group-item-info.list-group-item-action:focus {
  color: #004065;
  background-color: #a3cfe9;
}

.csn-theme .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #004065;
  border-color: #004065;
}

.csn-theme .list-group-item-warning {
  color: #7b0f13;
  background-color: #fabfc2;
}

.csn-theme .list-group-item-warning.list-group-item-action:hover, .csn-theme .list-group-item-warning.list-group-item-action:focus {
  color: #7b0f13;
  background-color: #f8a7ab;
}

.csn-theme .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7b0f13;
  border-color: #7b0f13;
}

.csn-theme .list-group-item-danger {
  color: #7b0f13;
  background-color: #fabfc2;
}

.csn-theme .list-group-item-danger.list-group-item-action:hover, .csn-theme .list-group-item-danger.list-group-item-action:focus {
  color: #7b0f13;
  background-color: #f8a7ab;
}

.csn-theme .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7b0f13;
  border-color: #7b0f13;
}

.csn-theme .list-group-item-light {
  color: gray;
  background-color: #fcfcfc;
}

.csn-theme .list-group-item-light.list-group-item-action:hover, .csn-theme .list-group-item-light.list-group-item-action:focus {
  color: gray;
  background-color: #efefef;
}

.csn-theme .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: gray;
  border-color: gray;
}

.csn-theme .list-group-item-dark {
  color: #262626;
  background-color: #cccccc;
}

.csn-theme .list-group-item-dark.list-group-item-action:hover, .csn-theme .list-group-item-dark.list-group-item-action:focus {
  color: #262626;
  background-color: #bfbfbf;
}

.csn-theme .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #262626;
  border-color: #262626;
}

.csn-theme .close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.csn-theme .close:hover, .csn-theme .close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

.csn-theme .close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.csn-theme button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.csn-theme .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}

.csn-theme .tooltip.show {
  opacity: 0.9;
}

.csn-theme .tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.csn-theme .tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.csn-theme .bs-tooltip-top, .csn-theme .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.csn-theme .bs-tooltip-top .arrow, .csn-theme .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.csn-theme .bs-tooltip-top .arrow::before, .csn-theme .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.csn-theme .bs-tooltip-right, .csn-theme .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.csn-theme .bs-tooltip-right .arrow, .csn-theme .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.csn-theme .bs-tooltip-right .arrow::before, .csn-theme .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.csn-theme .bs-tooltip-bottom, .csn-theme .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.csn-theme .bs-tooltip-bottom .arrow, .csn-theme .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.csn-theme .bs-tooltip-bottom .arrow::before, .csn-theme .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.csn-theme .bs-tooltip-left, .csn-theme .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.csn-theme .bs-tooltip-left .arrow, .csn-theme .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.csn-theme .bs-tooltip-left .arrow::before, .csn-theme .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.csn-theme .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.csn-theme .modal-open {
  overflow: hidden;
}

.csn-theme .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .csn-theme .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.csn-theme .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .csn-theme .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .csn-theme .modal-dialog {
    transition: none;
  }
}

.modal.show .csn-theme .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.csn-theme .modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.csn-theme .modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.csn-theme .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.csn-theme .modal-backdrop.fade {
  opacity: 0;
}

.csn-theme .modal-backdrop.show {
  opacity: 0.5;
}

.csn-theme .modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.csn-theme .modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.csn-theme .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.csn-theme .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.csn-theme .modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #f2f2f2;
}

.csn-theme .modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.csn-theme .modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.csn-theme .modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .csn-theme .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .csn-theme .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .csn-theme .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .csn-theme .modal-lg {
    max-width: 800px;
  }
}

.csn-theme .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.csn-theme .popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.csn-theme .popover .arrow::before, .csn-theme .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.csn-theme .bs-popover-top, .csn-theme .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.csn-theme .bs-popover-top .arrow, .csn-theme .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.csn-theme .bs-popover-top .arrow::before, .csn-theme .bs-popover-auto[x-placement^="top"] .arrow::before,
.csn-theme .bs-popover-top .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.csn-theme .bs-popover-top .arrow::before, .csn-theme .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}


.csn-theme .bs-popover-top .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.csn-theme .bs-popover-right, .csn-theme .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.csn-theme .bs-popover-right .arrow, .csn-theme .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.csn-theme .bs-popover-right .arrow::before, .csn-theme .bs-popover-auto[x-placement^="right"] .arrow::before,
.csn-theme .bs-popover-right .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.csn-theme .bs-popover-right .arrow::before, .csn-theme .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}


.csn-theme .bs-popover-right .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.csn-theme .bs-popover-bottom, .csn-theme .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.csn-theme .bs-popover-bottom .arrow, .csn-theme .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.csn-theme .bs-popover-bottom .arrow::before, .csn-theme .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.csn-theme .bs-popover-bottom .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.csn-theme .bs-popover-bottom .arrow::before, .csn-theme .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}


.csn-theme .bs-popover-bottom .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.csn-theme .bs-popover-bottom .popover-header::before, .csn-theme .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.csn-theme .bs-popover-left, .csn-theme .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.csn-theme .bs-popover-left .arrow, .csn-theme .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.csn-theme .bs-popover-left .arrow::before, .csn-theme .bs-popover-auto[x-placement^="left"] .arrow::before,
.csn-theme .bs-popover-left .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.csn-theme .bs-popover-left .arrow::before, .csn-theme .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}


.csn-theme .bs-popover-left .arrow::after,
.csn-theme .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.csn-theme .popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.csn-theme .popover-header:empty {
  display: none;
}

.csn-theme .popover-body {
  padding: 0.5rem 0.75rem;
  color: #4a4a4a;
}

.csn-theme .carousel {
  position: relative;
}

.csn-theme .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.csn-theme .carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .csn-theme .carousel-item {
    transition: none;
  }
}

.csn-theme .carousel-item.active,
.csn-theme .carousel-item-next,
.csn-theme .carousel-item-prev {
  display: block;
}

.csn-theme .carousel-item-next,
.csn-theme .carousel-item-prev {
  position: absolute;
  top: 0;
}

.csn-theme .carousel-item-next.carousel-item-left,
.csn-theme .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .csn-theme .carousel-item-next.carousel-item-left,
  .csn-theme .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.csn-theme .carousel-item-next,
.csn-theme .active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .csn-theme .carousel-item-next,
  .csn-theme .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.csn-theme .carousel-item-prev,
.csn-theme .active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .csn-theme .carousel-item-prev,
  .csn-theme .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.csn-theme .carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.csn-theme .carousel-fade .carousel-item.active,
.csn-theme .carousel-fade .carousel-item-next.carousel-item-left,
.csn-theme .carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.csn-theme .carousel-fade .active.carousel-item-left,
.csn-theme .carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.csn-theme .carousel-fade .carousel-item-next,
.csn-theme .carousel-fade .carousel-item-prev,
.csn-theme .carousel-fade .carousel-item.active,
.csn-theme .carousel-fade .active.carousel-item-left,
.csn-theme .carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .csn-theme .carousel-fade .carousel-item-next,
  .csn-theme .carousel-fade .carousel-item-prev,
  .csn-theme .carousel-fade .carousel-item.active,
  .csn-theme .carousel-fade .active.carousel-item-left,
  .csn-theme .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.csn-theme .carousel-control-prev,
.csn-theme .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.csn-theme .carousel-control-prev:hover, .csn-theme .carousel-control-prev:focus,
.csn-theme .carousel-control-next:hover,
.csn-theme .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.csn-theme .carousel-control-prev {
  left: 0;
}

.csn-theme .carousel-control-next {
  right: 0;
}

.csn-theme .carousel-control-prev-icon,
.csn-theme .carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.csn-theme .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.csn-theme .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.csn-theme .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.csn-theme .carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}

.csn-theme .carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.csn-theme .carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.csn-theme .carousel-indicators .active {
  background-color: #fff;
}

.csn-theme .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
/*# sourceMappingURL=farmmachinerysales.css.map */